import React from 'react';
import {
  AppBar,
  Box,
  Chip,
  Dialog,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  categoriesContainer: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  categoryTitle: {
    marginBottom: theme.spacing(2),
  },
  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginTop: '1rem',
      marginLeft: '1rem',
    },
    marginLeft: '-1rem',
    marginTop: '-1rem',
    width: 'calc(100% + 1rem)',
  },
}));

function TagsModal({open, onClose, tags, tagCategories}) {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const categoriesAndTags = React.useMemo(() => {
    const data = [];
    if (tagCategories?.length && tags?.length) {
      for (let i = 0; i < tagCategories.length; i += 1) {
        data[i] = {
          category: tagCategories[i],
          tags: tags.filter(tag => tag.categoryID === tagCategories[i]._id),
        };
      }
    }
    return data;
  }, [tags, tagCategories]);

  return (
    <Dialog
      fullScreen={isSmallScreen}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      scroll="body"
    >
      <AppBar position="static" variant="outlined" color="default">
        <Toolbar>
          <Typography variant="h6" style={{flexGrow: 1}}>
            Tags
          </Typography>
          <IconButton onClick={onClose} edge="start" color="inherit" aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box className={classes.categoriesContainer} py={3} px={2}>
        {categoriesAndTags.map(data => (
          <div key={data.category._id}>
            <Typography className={classes.categoryTitle} variant="h6">
              {data.category.title}
            </Typography>
            <div className={classes.tagsContainer}>
              {data.tags.map(tag => (
                <Chip
                  key={tag._id}
                  label={tag.title}
                  variant="outlined"
                  icon={
                    <img
                      style={{
                        display: 'block',
                        width: '1rem',
                        height: '1rem',
                        objectFit: 'contain',
                      }}
                      src={`https://projectres.blob.core.windows.net/icons/${tag.icon}`}
                      alt={tag.title}
                    />
                  }
                />
              ))}
            </div>
          </div>
        ))}
      </Box>
    </Dialog>
  );
}

TagsModal.propTypes = {
  open: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      title: PropTypes.string,
      categoryID: PropTypes.string,
      icon: PropTypes.string,
    })
  ).isRequired,
  tagCategories: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
};

export default TagsModal;
