import {useParams} from 'react-router-dom';
import React from 'react';
import {useGetRestaurantDetailsByIdQuery} from 'redux/query/server';

function useRestaurantDetails(options) {
  const {id} = useParams();
  const {data, isLoading, isError, isUninitialized, isSuccess} = useGetRestaurantDetailsByIdQuery(
    id,
    options
  );

  const skeleton = !isSuccess || isLoading || isError || isUninitialized;
  const restaurantDetails = React.useMemo(() => ({data: data ?? {}, skeleton}), [data, skeleton]);
  return restaurantDetails;
}

export {useRestaurantDetails};
