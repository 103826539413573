import {Box, Dialog, useMediaQuery} from '@material-ui/core';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  reservationModalOpenSelect,
  reservationModalTypeSelect,
  RESERVATION_COMPLETE_MODAL_TYPE,
  setReservationModalOpen,
  SLOTS_MODAL_TYPE,
} from 'redux/slices/reservation';
import ReserveNowModalContent from './ReserveNowModalContent';
import ReservationModalHeader from './ReservationModalHeader';
import FindSlotsModalContent from './FindSlotsModalContent';

function ReservationModal() {
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const isReservationModalOpen = useSelector(reservationModalOpenSelect);
  const reservationModalType = useSelector(reservationModalTypeSelect);

  return (
    <Dialog
      fullScreen={isSmallScreen}
      open={isReservationModalOpen}
      onClose={() => {
        dispatch(setReservationModalOpen(false));
      }}
      fullWidth
      maxWidth="sm"
      scroll="body"
    >
      <ReservationModalHeader />
      <Box py={3} px={2}>
        {reservationModalType === SLOTS_MODAL_TYPE && <FindSlotsModalContent />}
        {reservationModalType === RESERVATION_COMPLETE_MODAL_TYPE && <ReserveNowModalContent />}
      </Box>
    </Dialog>
  );
}

export default ReservationModal;
