import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {BASE_URL} from 'config/env';

export const serverApi = createApi({
  reducerPath: 'serverApi',
  baseQuery: fetchBaseQuery({baseUrl: `${BASE_URL}api/`}),
  endpoints: builder => ({
    getSlotsByRestaurantId: builder.query({
      async queryFn(id) {
        const response = await fetch(
          `https://slots-api.placemark-technologies.workers.dev/api/slots/${id}`
        );

        if (!response.ok) {
          return {
            error: response.status,
          };
        }

        const data = await response.json();

        return {data};
      },
    }),
    getRestaurantDetailsById: builder.query({
      query: id => ({
        url: `restaurant/restaurantDetails`,
        params: {
          restaurantId: id,
        },
      }),
      transformResponse: res => res.data,
    }),
    bookSlot: builder.mutation({
      query: async ({getAccessTokenSilently, ...body}) => {
        const accessToken = await getAccessTokenSilently();
        return {
          url: 'restaurants/reservation',
          method: 'POST',
          body,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
      },
      transformResponse: response => response.data,
    }),
    toggleRestaurantLike: builder.mutation({
      queryFn: async args => {
        try {
          const response = await fetch(`${BASE_URL}api/customers/hitList`, {
            method: 'PUT',
            body: JSON.stringify(args),
          });
          const data = await response.json();
          if (!response.ok || data?.code !== 200) {
            return {
              error: data.status,
            };
          }
          return {data: data.status};
        } catch (error) {
          return {error: 'REQUEST_FAILED'};
        }
      },
      async onQueryStarted({restaurantId}, {dispatch, queryFulfilled}) {
        const patchResult = dispatch(
          serverApi.util.updateQueryData('getRestaurantDetailsById', restaurantId, draft => {
            Object.assign(draft, {isLiked: !draft.isLiked});
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useBookSlotMutation,
  useGetRestaurantDetailsByIdQuery,
  useLazyGetSlotsByRestaurantIdQuery,
  useGetSlotsByRestaurantIdQuery,
  useToggleRestaurantLikeMutation,
} = serverApi;
