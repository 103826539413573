import React from 'react';
import {Typography} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import TagsButton from './TagsButton';
import {useRestaurantDetails} from '../hooks/useRestaurantDetails';

function RestaurantName() {
  const {
    data: {name, neighborhood, cuisineCategory, tags, tagcategories: tagCategories, featureTags},
    skeleton,
  } = useRestaurantDetails();
  return (
    <div>
      <Typography variant="h3">{skeleton ? <Skeleton width={400} /> : name}</Typography>
      <Typography variant="h6">
        {skeleton ? <Skeleton style={{display: 'inline-block'}} width={30} /> : neighborhood} ·{' '}
        {skeleton ? <Skeleton style={{display: 'inline-block'}} width={40} /> : cuisineCategory}{' '}
        <span>
          <TagsButton
            tags={tags}
            featureTags={featureTags}
            tagCategories={tagCategories}
            skeleton={skeleton}
          />
        </span>
      </Typography>
    </div>
  );
}

export default RestaurantName;
