import React from 'react';
import PropTypes from 'prop-types';
import {Skeleton} from '@material-ui/lab';
import {Box} from '@material-ui/core';

function CuisineAccordionSkeleton({side}) {
  return (
    <Box mb={2} mr={side === 'left' ? 2 : 0} ml={side === 'right' ? 2 : 0}>
      <Skeleton variant="rect" height={30} />
    </Box>
  );
}

CuisineAccordionSkeleton.propTypes = {
  side: PropTypes.oneOf(['left', 'right']).isRequired,
};

export default CuisineAccordionSkeleton;
