import React from 'react';
import PropTypes from 'prop-types';
import {FaFacebook, FaGoogle} from 'react-icons/fa';
import {makeStyles} from '@material-ui/core';
import Review from './Review';

const useStyles = makeStyles(() => ({
  container: ({direction}) => ({
    display: 'flex',
    flexDirection: direction,
    '& > * + *': {
      marginTop: direction === 'column' ? '1rem' : 0,
      marginLeft: direction === 'row' ? '2rem' : 0,
    },
  }),
}));

function RestaurantReviews({
  googleRating,
  googleRatingsTotal,
  facebookRating,
  facebookRatingsTotal,
  direction,
  skeleton,
}) {
  const classes = useStyles({direction});

  return (
    <div className={classes.container}>
      <Review
        IconComponent={FaGoogle}
        review={skeleton ? 0 : googleRating}
        totalReviews={skeleton ? 0 : googleRatingsTotal}
      />

      <Review
        IconComponent={FaFacebook}
        review={skeleton ? 0 : facebookRating}
        totalReviews={skeleton ? 0 : facebookRatingsTotal}
      />
    </div>
  );
}

RestaurantReviews.propTypes = {
  googleRating: PropTypes.number,
  googleRatingsTotal: PropTypes.number,
  facebookRating: PropTypes.number,
  facebookRatingsTotal: PropTypes.number,
  direction: PropTypes.oneOf(['row', 'column']),
  skeleton: PropTypes.bool,
};

RestaurantReviews.defaultProps = {
  googleRating: 0,
  googleRatingsTotal: 0,
  facebookRating: 0,
  facebookRatingsTotal: 0,
  direction: 'row',
  skeleton: false,
};

export default RestaurantReviews;
