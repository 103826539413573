import React from 'react';
import moment from 'moment';
import {useDispatch} from 'react-redux';
import {setReservationPartySizeAndDateRange} from 'redux/slices/reservation';

function useSetupPartySizeAndDateRange(settings = {}) {
  const {bookingTimeSpan, partySizeViseSlots: partySizeWiseSlots} = settings;
  const dispatch = useDispatch();
  const data = React.useMemo(() => {
    if (!bookingTimeSpan || !partySizeWiseSlots?.length) {
      return null;
    }
    let $minPartySize = Number.MAX_VALUE;
    let $maxPartySize = Number.MIN_VALUE;
    const $minReservationDate = moment().format();
    // const $minReservationDate = '2021-06-09T00:00:00.000Z'; // temp value
    const $maxReservationDate = moment($minReservationDate)
      .add(bookingTimeSpan - 1, 'days')
      .utc()
      .format();

    partySizeWiseSlots.forEach(({partySize}) => {
      $minPartySize = partySize < $minPartySize ? partySize : $minPartySize;
      $maxPartySize = partySize > $maxPartySize ? partySize : $maxPartySize;
    });
    return {
      minPartySize: $minPartySize,
      maxPartySize: $maxPartySize,
      minReservationDate: $minReservationDate,
      maxReservationDate: $maxReservationDate,
    };
  }, [bookingTimeSpan, partySizeWiseSlots]);

  React.useEffect(() => {
    if (data) dispatch(setReservationPartySizeAndDateRange(data));
  }, [data, dispatch]);
}

export {useSetupPartySizeAndDateRange};
