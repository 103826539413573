import React from 'react';
import PropTypes from 'prop-types';
import {useTheme} from '@material-ui/core/styles';
import {useMediaQuery, Button} from '@material-ui/core';

/**
 * This button's size will be small for xs screens & normal for bigger screens
 */
function ResponsiveButton({fullWidth, variant, color, type, disabled, onClick, endIcon, children}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Button
      onClick={onClick}
      size={isSmallScreen ? 'small' : 'medium'}
      fullWidth={fullWidth}
      variant={variant}
      color={color}
      type={type}
      endIcon={endIcon}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

/**
 * @see https://material-ui.com/api/button/
 */
ResponsiveButton.propTypes = {
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  endIcon: PropTypes.node,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

ResponsiveButton.defaultProps = {
  fullWidth: false,
  variant: 'text',
  color: 'default',
  type: 'button',
  disabled: false,
  endIcon: undefined,
  onClick: undefined,
};

export default ResponsiveButton;
