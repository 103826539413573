import React from 'react';
import PropTypes from 'prop-types';
import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
  responsiveFontSizes,
  createMuiTheme,
} from '@material-ui/core';

const theme = responsiveFontSizes(
  createMuiTheme({
    overrides: {
      MuiCssBaseline: {
        '@global': {
          body: {
            'overflow-x': 'hidden',
          },
        },
      },
      MuiButton: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
        disableTouchRipple: true,
      },
      MuiButton: {
        disableElevation: true,
      },
      MuiContainer: {
        maxWidth: 'xl',
      },
    },
  })
);

function ThemeProvider({children}) {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProvider;
