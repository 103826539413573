import {getBlogsByCategory} from '../../api/blog';
import {PAGE_SIZE} from '../../config/env';
import {addBlogsById} from '../slices/blog';
import {
  appendBlogsByCategory,
  setCategoryBlogsCurPage,
  setCategoryBlogsError,
  setCategoryBlogsHasNext,
  setCategoryBlogsLoading,
} from '../slices/category-blog';

export const getCategoryBlogPosts =
  ({category}) =>
  async (dispatch, getState) => {
    try {
      await dispatch(setCategoryBlogsLoading({category, loading: true}));
      await dispatch(setCategoryBlogsError({category, error: ''}));
      const {categoryBlog} = getState();
      const {curPage} = categoryBlog.byCategory[category];
      const blogs = await getBlogsByCategory({
        category,
        _start: curPage * PAGE_SIZE,
        _limit: PAGE_SIZE,
      });

      if (blogs?.length) {
        await dispatch(addBlogsById(blogs));
        await dispatch(appendBlogsByCategory({category, blogs}));
        await dispatch(setCategoryBlogsCurPage({category, curPage: curPage + 1}));
      }
      if (blogs?.length !== PAGE_SIZE) {
        await dispatch(setCategoryBlogsHasNext({category, hasNext: false}));
      }
    } catch (e) {
      // console.log(e);
      dispatch(
        setCategoryBlogsError({
          category,
          error: 'Failed to fetch the category blog posts',
        })
      );
    } finally {
      dispatch(setCategoryBlogsLoading({category, loading: false}));
    }
  };
