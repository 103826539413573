import {Box, Container, makeStyles, Typography, useMediaQuery} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import React from 'react';
import ClampLines from 'react-clamp-lines';
import ToggleLikeIconButton from '../Button/ToggleLikeIconButton';
import RestaurantDetailsCard from '../Card/RestaurantDetailsCard';
import RestaurantSlotsSearchCard from '../Card/RestaurantSlotsSearchCard';
import RestaurantWaitlistCard from '../Card/RestaurantWaitlistCard';
import {useRestaurantDetails} from '../hooks/useRestaurantDetails';
import RelatedRestaurants from '../UI/RelatedRestaurants';
import RestaurantAward from '../UI/RestaurantAward';
import RestaurantCriticReview from '../UI/RestaurantCriticReview';
import RestaurantName from '../UI/RestaurantName';
import RestaurantReviews from '../UI/RestaurantReviews';
import ShowOrHideRestaurantCriticReviews from '../UI/ShowOrHideRestaurantCriticReviews';
import StickyPanel from './StickyPanel';

const useStyles = makeStyles(theme => ({
  contentSection: {
    display: 'flex',
  },
  contentContainer: {
    paddingRight: theme.typography.pxToRem(theme.spacing(4)),
    flexBasis: '68%',
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
      paddingRight: 0,
    },
  },
  stickyPanel: {
    flexBasis: '32%',
    flexShrink: 0,
    position: 'sticky',
    top: '5rem',
    height: '35rem',
    '& > * + *': {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  restaurantBio: {
    '& > #clamped-content-restaurant-bio': {
      ...theme.typography.body1,
      margin: '0 0 0.2rem',
    },
    '& > .clamp-lines__button': {
      ...theme.typography.subtitle2,
      background: 'none',
      border: 'none',
      color: theme.palette.primary.main,
      cursor: 'pointer',
      padding: 0,
      '&:hover, &:focus': {
        textDecoration: 'underline',
      },
    },
  },
  restaurantAwards: {
    gap: '1rem',
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(300px, 1fr))`,
  },
  criticReviews: {
    '& > *:not(:last-child)': {
      marginBottom: '1rem',
    },
  },
  slotsSearchContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: theme.breakpoints.values.sm,
    marginInline: 'auto',
    marginBlock: theme.spacing(4),
    '& > * + *': {
      marginTop: theme.spacing(4),
    },
  },
}));

function RestaurantContentSection() {
  const classes = useStyles();
  const {data: restaurantDetails, skeleton} = useRestaurantDetails();
  const isDownSM = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const isDownXS = useMediaQuery(theme => theme.breakpoints.down('xs'));

  return (
    <Container>
      <div className={classes.contentSection}>
        <div className={classes.contentContainer}>
          <Box mb={3} display="flex" justifyContent="space-between" alignItems="flex-start">
            <RestaurantName
              name={restaurantDetails?.name}
              neighborhood={restaurantDetails?.neighborhood}
              cuisineCategory={restaurantDetails?.cuisineCategory}
              tags={restaurantDetails?.tags}
              featureTags={restaurantDetails?.featureTags}
              tagCategories={restaurantDetails?.tagcategories}
              skeleton={skeleton}
            />
            <ToggleLikeIconButton
              restaurantId={restaurantDetails?._id}
              isLiked={restaurantDetails?.isLiked}
              skeleton={skeleton}
            />
          </Box>
          <Box mb={3}>
            <RestaurantReviews
              googleRating={restaurantDetails?.googleRating}
              googleRatingsTotal={restaurantDetails?.googleRatingsTotal}
              facebookRating={restaurantDetails?.facebookRating}
              facebookRatingsTotal={restaurantDetails?.facebookRatingsTotal}
              direction={isDownXS ? 'column' : 'row'}
              skeleton={skeleton}
            />
          </Box>

          <>
            {skeleton ? (
              <Typography>
                <Skeleton />
                <Skeleton width="98%" />
                <Skeleton width="50%" />
              </Typography>
            ) : (
              restaurantDetails?.bio && (
                <ClampLines
                  text={restaurantDetails.bio}
                  id="restaurant-bio"
                  lines={2}
                  ellipsis="..."
                  moreText="Show More"
                  lessText="Show Less"
                  className={classes.restaurantBio}
                  innerElement="p"
                />
              )
            )}
          </>
          {isDownSM && (
            <div className={classes.slotsSearchContainer}>
              <RestaurantWaitlistCard />
              <RestaurantSlotsSearchCard />
            </div>
          )}
          <Box my={5}>
            <div className={classes.restaurantAwards}>
              {!skeleton &&
                !!restaurantDetails?.awards?.length &&
                restaurantDetails.awards.map(award => (
                  <RestaurantAward
                    key={award.title}
                    title={award.title}
                    icon={award.icon}
                    awardName={award.awardName}
                    year={award.year}
                  />
                ))}
              {skeleton && <RestaurantAward skeleton />}
            </div>
          </Box>

          <Box className={classes.criticReviews} my={5}>
            {!skeleton && !!restaurantDetails?.reviews?.length && (
              <ShowOrHideRestaurantCriticReviews
                height={150}
                reviews={restaurantDetails?.reviews}
              />
            )}
            {skeleton && <RestaurantCriticReview skeleton />}
          </Box>

          <Box my={5}>
            <RestaurantDetailsCard
              coordinates={restaurantDetails?.loc?.coordinates}
              faqs={restaurantDetails?.FAQ}
              socialHandles={restaurantDetails?.socialHandles}
              restaurantName={restaurantDetails?.name}
              weeklySchedule={restaurantDetails?.days}
              address={restaurantDetails?.address}
              website={restaurantDetails?.website}
              phoneNo={restaurantDetails?.phoneNo}
              skeleton={skeleton}
              direction={isDownSM ? 'column' : 'row'}
            />
          </Box>
        </div>
        <StickyPanel
          name={restaurantDetails?.name}
          neighborhood={restaurantDetails?.neighborhood}
          cuisineCategory={restaurantDetails?.cuisineCategory}
          settings={restaurantDetails?.settings}
          skeleton={skeleton}
          className={classes.stickyPanel}
        />
      </div>
      <Box my={5}>
        <RelatedRestaurants
          skeleton={skeleton}
          restaurants={restaurantDetails?.relatedRestaurants}
        />
      </Box>
    </Container>
  );
}

export default RestaurantContentSection;
