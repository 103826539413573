import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Box, Divider, Grid, makeStyles} from '@material-ui/core';
import FeaturedBlogCard from '../Cards/FeaturedBlogCard/FeaturedBlogCard';
import BlogCard from '../Cards/BlogCard';
import {getFeaturedHomeBlogs} from '../../redux/thunks/getFeaturedHomeBlogs';
import OverlayError from '../Error/OverlayError';

const useStyles = makeStyles(() => ({
  grid: {
    overflowX: 'auto',
    '&>*': {
      flex: 1,
      maxWidth: '33.33%',
    },
  },
}));

/**
 * A Blogs grid for the home page
 */
function BlogsSection() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cityName = useSelector(state => state.city.currentCity?.cityName);
  const featuredBlogIds = useSelector(state => state.home.featuredBlogs.byCity[cityName]);
  const featuredBlogs = useSelector(state => {
    if (featuredBlogIds?.length) {
      return featuredBlogIds.map(id => state.blog.byId[id]);
    }
    return null;
  });
  const isLoading = useSelector(state => state.home.featuredBlogs.loading);
  const error = useSelector(state => state.home.featuredBlogs.error);
  const skeleton = isLoading || error || !featuredBlogs?.length;

  React.useEffect(() => {
    if (cityName?.trim()) {
      dispatch(getFeaturedHomeBlogs({cityName}));
    }
  }, [cityName, dispatch]);

  const retryHandler = () => {
    dispatch(getFeaturedHomeBlogs({cityName}));
  };

  return (
    <section>
      <OverlayError error={!!error} message={error} onRetry={retryHandler}>
        {!skeleton && (
          <FeaturedBlogCard
            id={featuredBlogs[0].slug.current}
            category={featuredBlogs[0].category}
            featureImage={featuredBlogs[0].mainImage.asset}
            title={featuredBlogs[0].title}
            skeleton={skeleton}
          />
        )}
        {skeleton && <FeaturedBlogCard skeleton />}
        <Box my={2}>
          <Divider light />
        </Box>
        <Grid
          className={classes.grid}
          container
          spacing={2}
          wrap="nowrap"
          direction="row"
          justify="space-between"
        >
          {!skeleton &&
            featuredBlogs.slice(1).map(blog => (
              <Grid key={blog.slug.current} item>
                <BlogCard
                  id={blog.slug.current}
                  category={blog.category}
                  featureImage={blog.mainImage.asset}
                  title={blog.title}
                  skeleton={skeleton}
                />
              </Grid>
            ))}
          {skeleton && (
            <>
              <Grid item>
                <BlogCard skeleton />
              </Grid>
              <Grid item>
                <BlogCard skeleton />
              </Grid>
              <Grid item>
                <BlogCard skeleton />
              </Grid>
              <Grid item>
                <BlogCard skeleton />
              </Grid>
            </>
          )}
        </Grid>
      </OverlayError>
    </section>
  );
}

export default BlogsSection;
