import React from 'react';
import PropTypes from 'prop-types';
import {Box, Button, List, ListItem, Typography} from '@material-ui/core';
import ArchiveBlogCard from '../Cards/ArchiveBlogCard';

/**
 * Shows list of the blog cards
 */
function BlogList({buttonProps, blogs, noData, skeleton}) {
  return (
    <List>
      {blogs &&
        blogs.map(blog => (
          <ListItem key={blog.id}>
            <ArchiveBlogCard
              author={blog.author}
              featureImage={blog.mainImage.asset}
              title={blog.title}
              id={blog.slug.current}
              // eslint-disable-next-line no-underscore-dangle
              publishedAt={blog._createdAt}
            />
          </ListItem>
        ))}
      {noData && (
        <ListItem>
          <Typography color="textSecondary" variant="h5">
            no content found
          </Typography>
        </ListItem>
      )}
      {skeleton && (
        <ListItem>
          <ArchiveBlogCard skeleton />
        </ListItem>
      )}
      <ListItem>
        <Box flex="1" display="flex" justifyContent="center" mt={5}>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            disabled={buttonProps.disabled}
            onClick={buttonProps.onClick}
          >
            load more
          </Button>
        </Box>
      </ListItem>
    </List>
  );
}

BlogList.propTypes = {
  /**
   * props for the `load more` button
   */
  buttonProps: PropTypes.shape({
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  }),
  /**
   * an array of blog posts data
   */
  blogs: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      title: PropTypes.string,
      mainImage: PropTypes.shape({
        asset: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
      author: PropTypes.shape({
        name: PropTypes.string,
        _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
      _createdAt: PropTypes.string,
      skeleton: PropTypes.bool,
    })
  ),
  /**
   * set it to true of blog list contains no data
   */
  noData: PropTypes.bool,
  /**
   * set it to true while fetching the blogs
   */
  skeleton: PropTypes.bool,
};

BlogList.defaultProps = {
  buttonProps: {},
  blogs: null,
  noData: false,
  skeleton: true,
};

export default BlogList;
