import moment from 'moment';

/**
 * @param {Number[]} days - number of days in ISO Weekday format
 * @returns String[] - for example, if the days are 1,2,3 then it'll return the ['Mon-Wed'] array
 */
function combineWeekdays(days) {
  const ascDays = days.sort();
  const combinedWeekdays = [];
  ascDays.forEach(dayNum => {
    const weekDay = moment().isoWeekday(dayNum).format('ddd');
    if (!combinedWeekdays.length) {
      combinedWeekdays.push(weekDay);
    } else {
      const lastCombinedWeekday = combinedWeekdays[combinedWeekdays.length - 1];
      const lastWeekday = moment()
        .isoWeekday(dayNum - 1)
        .format('ddd');
      if (lastCombinedWeekday.endsWith(lastWeekday)) {
        combinedWeekdays[combinedWeekdays.length - 1] = `${
          lastCombinedWeekday.split('-')[0]
        }-${weekDay}`;
      } else {
        combinedWeekdays.push(weekDay);
      }
    }
  });
  return combinedWeekdays;
}

export {combineWeekdays};
