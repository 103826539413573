import React from 'react';
import {fade, IconButton, makeStyles} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

const useStyles = makeStyles(theme => ({
  button: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: theme.typography.pxToRem(theme.spacing(1)),
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, theme.palette.action.hoverOpacity),
    },
    '&:disabled': {
      color: fade(theme.palette.common.white, theme.palette.action.disabledOpacity),
    },
    zIndex: 1,
  },
  icon: {
    fontSize: '3rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
}));

function SliderNextArrow(props) {
  const classes = useStyles();
  // eslint-disable-next-line react/prop-types
  const {currentSlide, onClick} = props;
  return (
    <IconButton
      className={classes.button}
      aria-label="previous"
      size="small"
      disabled={currentSlide === 0}
      onClick={onClick}
    >
      <NavigateBeforeIcon className={classes.icon} />
    </IconButton>
  );
}

export default SliderNextArrow;
