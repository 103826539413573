import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  body: {
    overflowX: 'scroll',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      overflowX: 'unset',
      paddingBottom: 0,
    },
  },
  grid: {
    display: 'grid',
    'grid-template-columns': 'repeat(3,minmax(230px,1fr))',
    'grid-column-gap': '15px',
    'grid-row-gap': '15px',
    width: theme.breakpoints.width('md'),
    [theme.breakpoints.up('lg')]: {
      'grid-template-columns': 'repeat(5,minmax(200px,1fr))',
    },
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      'grid-column-gap': '5px',
    },
  },
}));

/**
 * A grid container for the restaurents of the homepage
 */
function RestaurantGrid({children}) {
  const classes = useStyles();
  return (
    <div className={classes.body}>
      <div className={classes.grid}>{children}</div>
    </div>
  );
}

RestaurantGrid.propTypes = {
  /**
   * That'll be an array of the restaurant cards
   */
  children: PropTypes.node.isRequired,
};

export default RestaurantGrid;
