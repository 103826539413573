import React from 'react';
import moment from 'moment';

function useCurrentWeekTimings(weeklySchedule) {
  const data = React.useMemo(() => {
    let curData = null;
    const curWeekNumber = moment().isoWeekday();

    if (weeklySchedule) {
      curData = weeklySchedule.find(timings => {
        if (timings.dayNo === curWeekNumber) {
          return true;
        }
        return false;
      });
    }
    return curData;
  }, [weeklySchedule]);

  return data;
}

export {useCurrentWeekTimings};
