import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';
import Slider from 'react-slick';
import ModalGalleryThumbnailSliderItem from './ModalGalleryThumbnailSliderItem';

const useStyles = makeStyles(() => ({
  slider: {
    '& .slick-slide': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));

function ModalGalleryThumbnailSlider({slides, currentSlide, onSlideClick}) {
  const classes = useStyles();
  return (
    <Slider
      {...{
        arrows: true,
        draggable: true,
        slidesToScroll: 2,
        slidesToShow: slides.length < 5 ? slides.length : 5,
        initialSlide: currentSlide,
        responsive: [
          {
            breakpoint: 350,
            settings: {
              slidesToShow: slides.length < 4 ? slides.length : 4,
            },
          },
        ],
      }}
      className={classes.slider}
    >
      {slides.map((slide, index) => (
        <ModalGalleryThumbnailSliderItem
          key={`${slide.thumbnailUrl + index}`}
          index={index}
          onClick={onSlideClick}
          thumbnailUrl={slide.thumbnailUrl}
        />
      ))}
    </Slider>
  );
}

ModalGalleryThumbnailSlider.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      thumbnailUrl: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentSlide: PropTypes.number.isRequired,
  onSlideClick: PropTypes.bool.isRequired,
};

export default ModalGalleryThumbnailSlider;
