import {getBlogsByCity} from '../../api/blog';
import {PAGE_SIZE} from '../../config/env';
import {addBlogsById} from '../slices/blog';
import {
  appendBlogsByCity,
  setCityBlogsHasNext,
  setCityBlogsCurPage,
  setCityBlogsLoading,
  setCityBlogsError,
} from '../slices/city-blog';

export const getCityBlogPosts =
  ({city}) =>
  async (dispatch, getState) => {
    try {
      await dispatch(setCityBlogsLoading({city, loading: true}));
      await dispatch(setCityBlogsError({city, error: ''}));
      const {cityBlog} = getState();
      const {curPage} = cityBlog[city];
      const blogs = await getBlogsByCity({
        cityName: city,
        _start: curPage * PAGE_SIZE,
        _limit: PAGE_SIZE,
      });

      if (blogs?.length) {
        await dispatch(addBlogsById(blogs));
        await dispatch(appendBlogsByCity({city, blogs}));
        await dispatch(setCityBlogsCurPage({city, curPage: curPage + 1}));
      }
      if (blogs?.length !== PAGE_SIZE) {
        await dispatch(setCityBlogsHasNext({city, hasNext: false}));
      }
    } catch (e) {
      // console.log(e);
      dispatch(
        setCityBlogsError({
          city,
          error: 'Failed to fetch the city blog posts',
        })
      );
    } finally {
      dispatch(setCityBlogsLoading({city, loading: false}));
    }
  };
