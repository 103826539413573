import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, Typography} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  img: {
    display: 'inline-block',
    alignSelf: 'center',
    width: '2rem',
    height: '2rem',
    marginRight: '1rem',
    objectFit: 'contain',
  },
}));

const inlineBlockStyle = {display: 'inline-block'};

function RestaurantAward({title, icon, awardName, year, skeleton}) {
  const classes = useStyles();

  return (
    <div style={{display: 'flex'}}>
      {skeleton ? (
        <Skeleton variant="circle" width={50} height={50} style={{marginRight: '1rem'}} />
      ) : (
        <img src={icon} alt={title} className={classes.img} />
      )}
      <div>
        <Typography variant="h6">{skeleton ? <Skeleton width={200} /> : title}</Typography>
        <Typography variant="subtitle2">
          {skeleton ? <Skeleton width={50} style={inlineBlockStyle} /> : awardName},{' '}
          {skeleton ? <Skeleton width={40} style={inlineBlockStyle} /> : year}
        </Typography>
      </div>
    </div>
  );
}

RestaurantAward.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  awardName: PropTypes.string,
  year: PropTypes.string,
  skeleton: PropTypes.bool,
};

RestaurantAward.defaultProps = {
  title: null,
  icon: null,
  awardName: null,
  year: null,
  skeleton: false,
};

export default RestaurantAward;
