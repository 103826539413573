import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, Typography, Link as MuiLink} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import clsx from 'clsx';
import {Link as RouterLink} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  container: {minWidth: '270px'},
  featuredImage: {
    display: 'block',
    width: '100%',
    height: '156px',
    overflow: 'hidden',
    position: 'relative',
    backgroundImage: ({imageUrl, skeleton}) => (!skeleton ? `url(${imageUrl})` : undefined),
    backgroundColor: theme.palette.grey[100],
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  content: {
    position: 'relative',
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: `-${theme.spacing(5)}px`,
    backgroundColor: '#FFFFFF',
    padding: theme.spacing(2),
  },
  boldFont: {
    fontWeight: 'bold',
  },
  disabledLink: {
    outline: 'none',
    textDecoration: 'none',
    pointerEvents: 'none',
    '&:hover': {
      outline: 'none',
      textDecoration: 'none',
    },
  },
}));

/**
 * A blog card in the small size for the homepage
 */
function BlogCard({id, title, featureImage, category, skeleton}) {
  const classes = useStyles({imageUrl: featureImage?.url, skeleton});
  return (
    <div className={classes.container}>
      <MuiLink
        tabIndex="-1"
        aria-label={title}
        aria-hidden="true"
        to={skeleton ? `#!` : `/blog/${id}`}
        component={skeleton ? 'span' : RouterLink}
        className={clsx(classes.featuredImage, {
          [classes.disabledLink]: skeleton,
        })}
      >
        {skeleton && <Skeleton variant="rect" width="100%" height="100%" />}
      </MuiLink>
      <div className={classes.content}>
        <MuiLink
          tabIndex={skeleton ? '-1' : undefined}
          className={clsx({
            [classes.disabledLink]: skeleton,
          })}
          component={skeleton ? 'span' : RouterLink}
          to={skeleton ? `#!` : `/blog/category/${category.slug.current}`}
        >
          <Typography className={classes.boldFont} component="span" variant="caption">
            {skeleton ? <Skeleton width={40} /> : category.title}
          </Typography>
        </MuiLink>
        <MuiLink
          color="textPrimary"
          tabIndex={skeleton ? '-1' : undefined}
          className={clsx({
            [classes.disabledLink]: skeleton,
          })}
          component={skeleton ? 'span' : RouterLink}
          to={skeleton ? `#!` : `/blog/${id}`}
        >
          <Typography className={classes.boldFont} component="h2" variant="subtitle2">
            {skeleton ? (
              <>
                <Skeleton width="90%" />
                <Skeleton width="60%" />
              </>
            ) : (
              title
            )}
          </Typography>
        </MuiLink>
      </div>
    </div>
  );
}

BlogCard.propTypes = {
  /**
   * id is required to create the URL for the blog post
   */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * the title of the blog post
   */
  title: PropTypes.string,
  /**
   * a featured image for the blog post
   */
  featureImage: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  /**
   * blog category i.e. Guides, the hit list, new on resy
   */
  category: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.shape({current: PropTypes.string.isRequired}).isRequired,
  }),
  /**
   * set it to true if you want to show the loading skeleton (if true, then no other prop is required)
   */
  skeleton: PropTypes.bool,
};

BlogCard.defaultProps = {
  id: null,
  title: null,
  featureImage: null,
  category: null,
  skeleton: false,
};

export default BlogCard;
