import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  List,
  ListItem,
  styled,
  Toolbar,
  Typography,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import {getMenuSchedule} from '../utils/getMenuSchedule';

const CustomListItem = styled(ListItem)({
  display: 'grid',
  gridTemplateColumns: '1fr 2fr',
  paddingInline: 0,
});

function ScheduleListItem({menuName, daysAndTimings}) {
  return (
    <CustomListItem>
      <Typography variant="button">{menuName}</Typography>
      <div>
        {daysAndTimings.map(([timing, days], i) => (
          <div style={{marginBottom: i !== daysAndTimings.length - 1 ? '0.4rem' : 0}}>
            <Typography component="span">{days}:</Typography>{' '}
            <Typography component="span">{timing}</Typography>
          </div>
        ))}
      </div>
    </CustomListItem>
  );
}

ScheduleListItem.propTypes = {
  menuName: PropTypes.string.isRequired,
  daysAndTimings: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
};

function TimingsModal({weeklySchedule, open, onClose}) {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const schedule = React.useMemo(() => getMenuSchedule(weeklySchedule), [weeklySchedule]);
  const additionalSchedule = React.useMemo(
    () => getMenuSchedule(weeklySchedule, 'additionalHours'),
    [weeklySchedule]
  );

  return (
    <Dialog
      fullScreen={isSmallScreen}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      scroll="body"
    >
      <AppBar position="static" variant="outlined" color="default">
        <Toolbar>
          <Typography variant="h6" style={{flexGrow: 1}}>
            Timings
          </Typography>
          <IconButton onClick={onClose} edge="start" color="inherit" aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box py={3} px={2}>
        <List>
          {schedule?.map(([menuName, daysAndTimings]) => (
            <ScheduleListItem key={menuName} menuName={menuName} daysAndTimings={daysAndTimings} />
          ))}
        </List>
        <Box mt={2}>
          <Accordion square>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Special Menu</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {Boolean(additionalSchedule?.length) && (
                <List>
                  {additionalSchedule.map(([menuName, daysAndTimings]) => (
                    <ScheduleListItem
                      key={menuName}
                      menuName={menuName}
                      daysAndTimings={daysAndTimings}
                    />
                  ))}
                </List>
              )}
              {!additionalSchedule?.length && (
                <Typography color="textSecondary">No Upcoming Event</Typography>
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </Dialog>
  );
}

TimingsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  weeklySchedule: PropTypes.arrayOf(
    PropTypes.shape({
      isOpen: PropTypes.bool,
      _id: PropTypes.string,
      dayNo: PropTypes.number,
      hours: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          from: PropTypes.number,
          additionTo: PropTypes.number,
        })
      ),
      additionalHours: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          from: PropTypes.number,
          additionTo: PropTypes.number,
        })
      ),
    })
  ).isRequired,
};

export default TimingsModal;
