import {createSlice} from '@reduxjs/toolkit';

const INITIAL_TAG_BLOGS_DATA = {
  data: [],
  tagDescription: '',
  error: '',
  loading: true,
  curPage: 0,
  hasNext: true,
};

const tagBlogSlice = createSlice({
  name: 'tag-blog',
  initialState: {
    byTag: {},
  },
  reducers: {
    prepareInitialTagBlogsState(state, action) {
      if (!state.byTag[action.payload]) {
        state.byTag[action.payload] = INITIAL_TAG_BLOGS_DATA;
      }
    },
    appendBlogsByTag: {
      reducer(state, action) {
        state.byTag[action.payload.tag].data.push(...action.payload.blogs);
      },
      prepare({tag, blogs}) {
        const blogIds = blogs.map(blog => blog.slug.current);
        return {payload: {tag, blogs: blogIds}};
      },
    },
    setTagBlogsCurPage(state, action) {
      state.byTag[action.payload.tag].curPage = action.payload.curPage;
    },
    setTagBlogsHasNext(state, action) {
      state.byTag[action.payload.tag].hasNext = action.payload.hasNext;
    },
    setTagBlogsLoading(state, action) {
      state.byTag[action.payload.tag].loading = action.payload.loading;
    },
    setTagBlogsError(state, action) {
      state.byTag[action.payload.tag].error = action.payload.error;
    },
  },
});

export const {
  prepareInitialTagBlogsState,
  appendBlogsByTag,
  setTagBlogsCurPage,
  setTagBlogsHasNext,
  setTagBlogsLoading,
  setTagBlogsError,
} = tagBlogSlice.actions;

export default tagBlogSlice.reducer;
