import React from 'react';
import {useSelector} from 'react-redux';
import {Helmet} from 'react-helmet';
import {Link as RouterLink} from 'react-router-dom';
import {makeStyles, Box, Container, Link as MuiLink, Typography} from '@material-ui/core';

import BlogsSection from '../components/Home/BlogsSection';
import Layout from '../components/Layout';
import FeaturedRestaurantsSections from '../components/Home/FeaturedRestaurantsSections';
import NeighborhoodSection from '../components/Home/NeighborhoodSection';
import LandmarkSection from '../components/Home/LandmarkSection';

const useStyles = makeStyles(() => ({
  breakOut: {
    width: '100vw',
    position: 'relative',
    left: 'calc(-1 * (100vw - 100%) / 2)',
  },
  colorLight: {
    color: 'rgba(70, 70, 70, 0.87)',
  },
  gridList: {
    // width: '350px !important',
    height: '350px !important',
  },
  cityTitle: {
    marginTop: '60px',
    fontSize: '80px',
    '&:hover': {
      display: 'none',
    },
  },
  cityCard: {
    height: '200px !important',
    background: `url('https://images.deliveryhero.io/image/foodpanda/city-tile-pk/Multan.jpg')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    width: '23% !important',
    margin: '5px',
    position: 'relative',
    '&:hover + .cityCard': {
      display: 'none',
    },
  },
}));

/**
 * It's a homepage component with the `/` route
 */
const Home = () => {
  const classes = useStyles();
  const city = useSelector(state => state.city.currentCity);

  return (
    <Layout>
      <Helmet>
        <title>Khaaba</title>
      </Helmet>
      <Container>
        <Box my={5}>
          <BlogsSection />
        </Box>
        <Box bgcolor="white" className={classes.breakOut} py={5} my={5}>
          <Container maxWidth="sm">
            <Typography gutterBottom variant="h6">
              Discover restaurants to love.
            </Typography>
            <Typography gutterBottom variant="subtitle2">
              Now is the time to support restaurants. With Resy stories and guides, get the latest
              intel on your favorite spots and find new openings in your city.
            </Typography>
            <MuiLink to="/blog/archive" component={RouterLink}>
              Read more
            </MuiLink>
          </Container>
        </Box>
        <FeaturedRestaurantsSections city={city} />
        <Box my={5}>
          <NeighborhoodSection city={city} />
        </Box>

        <Box my={5}>
          <LandmarkSection city={city} />
        </Box>
      </Container>
    </Layout>
  );
};

export default Home;
