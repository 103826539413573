import {getFeaturedListsByCity as getFeaturedListsByCityApi} from '../../api/city';

import {setFeaturedLists, setFeaturedListsError, setFeaturedListsLoading} from '../slices/city';

export const getFeaturedListsByCity = ({_id: cityId}) => async (dispatch, getState) => {
  try {
    await dispatch(setFeaturedListsLoading(true));
    await dispatch(setFeaturedListsError(''));

    const {
      city: {
        featuredLists: {restaurantLists, landMarks, neighbourhood, cuisinesList},
      },
    } = getState();

    const isCached = Boolean(
      restaurantLists.byCity?.[cityId] ||
        landMarks.byCity?.[cityId] ||
        neighbourhood.byCity?.[cityId] ||
        cuisinesList.byCity?.[cityId]
    );

    if (isCached) return;

    const featuredLists = await getFeaturedListsByCityApi(cityId);

    if (featuredLists.status === 200 && featuredLists.data?.code === 200) {
      const cityDetails = featuredLists.data.data;
      await dispatch(setFeaturedLists(cityDetails));
    } else {
      throw new Error();
    }
  } catch (e) {
    // console.log(e);
    dispatch(setFeaturedListsError('Failed to fetch the city featured lists'));
  } finally {
    dispatch(setFeaturedListsLoading(false));
  }
};
