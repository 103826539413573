import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MenuItem, Select, makeStyles} from '@material-ui/core';
import {setCurrentCity} from '../../redux/slices/city';
import {getFeaturedListsByCity} from '../../redux/thunks/getFeaturedListsByCity';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.subtitle2,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    padding: 0,
  },
  icon: {
    color: theme.palette.primary.light,
  },
}));

/**
 * this component will allow us to select the city from a given list
 */
function CitySelector() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentCity = useSelector(state => state.city.currentCity);
  const cities = useSelector(state => state.city.cities);
  const loading = useSelector(state => state.city.loading);
  const error = useSelector(state => state.city.error);
  const disabled = !!(!cities || loading || error);
  const selectedValue = disabled ? '' : currentCity?.cityName;

  React.useEffect(() => {
    if (currentCity) {
      dispatch(getFeaturedListsByCity(currentCity));
    }
  }, [currentCity, dispatch]);

  const cityChangeHandler = e => {
    const curCity = cities.find(city => city.cityName === e.target.value);
    dispatch(setCurrentCity(curCity));
  };
  return (
    <Select
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      }}
      disabled={disabled}
      disableUnderline
      value={selectedValue ?? ''}
      autoCapitalize="true"
      classes={{
        root: classes.root,
        icon: classes.icon,
      }}
      displayEmpty
      onChange={cityChangeHandler}
    >
      <MenuItem value="" disabled>
        Select City
      </MenuItem>
      {!disabled &&
        cities.map(({_id, cityName}) => (
          <MenuItem key={_id} value={cityName}>
            {cityName}
          </MenuItem>
        ))}
    </Select>
  );
}

export default CitySelector;
