import React from 'react';
import {Typography, Button, makeStyles, CircularProgress} from '@material-ui/core';
import Moment from 'react-moment';
import {useRestaurantDetails} from 'components/RestaurantProfile/hooks/useRestaurantDetails';
import {useDispatch, useSelector} from 'react-redux';
import {
  reservationCurrentSlotSelect,
  reservationPartySizeCurrentSelect,
  setReservationModalType,
  SLOTS_MODAL_TYPE,
} from 'redux/slices/reservation';
import {AiOutlineCalendar} from 'react-icons/ai';
import {BsFillPeopleFill} from 'react-icons/bs';
import clsx from 'clsx';
import {useBookSlotMutation} from 'redux/query/server';
import {useAuth0} from '@auth0/auth0-react';

const useStyles = makeStyles(theme => ({
  mb4: {
    marginBottom: theme.spacing(4),
  },
  mb3: {
    marginBottom: theme.spacing(3),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  mr2: {
    marginRight: theme.spacing(2),
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function ReserveNowModalContent() {
  const classes = useStyles();
  const {
    data: {name, _id: restaurantId},
  } = useRestaurantDetails();
  const dispatch = useDispatch();
  const selectedSlot = useSelector(reservationCurrentSlotSelect);
  const partySize = useSelector(reservationPartySizeCurrentSelect);
  const [bookSlot, bookSlotStatus] = useBookSlotMutation();
  const {isAuthenticated, getAccessTokenSilently} = useAuth0();

  const isDataValid = !!selectedSlot && !!partySize;

  const bookSlotHandler = React.useCallback(() => {
    bookSlot({...selectedSlot, partySize, restaurantId, getAccessTokenSilently});
  }, [bookSlot, getAccessTokenSilently, partySize, restaurantId, selectedSlot]);

  React.useEffect(() => {
    if (!isAuthenticated) {
      dispatch(setReservationModalType(SLOTS_MODAL_TYPE));
    }
  }, [dispatch, isAuthenticated]);

  return (
    <div>
      <Typography className={classes.mb3} variant="h5">
        Complete your reservation
      </Typography>
      <Typography className={classes.mb2} variant="h6">
        {name}
      </Typography>
      <Typography className={clsx(classes.label, classes.mb2)}>
        <AiOutlineCalendar className={classes.mr2} fill="#000" stroke="#000" fontSize="1.2em" />{' '}
        <Moment format="ddd, MMM d, yyyy hh:mm A" date={selectedSlot?.date?.start} />
      </Typography>
      <Typography className={clsx(classes.label, classes.mb4)}>
        <BsFillPeopleFill className={classes.mr2} fontSize="1.2em" /> Party of {partySize}
      </Typography>
      <Button
        onClick={bookSlotHandler}
        fullWidth
        size="large"
        variant="contained"
        color="primary"
        disabled={bookSlotStatus.isLoading || !isDataValid}
        endIcon={bookSlotStatus.isLoading ? <CircularProgress size="1rem" /> : null}
      >
        Reserve Now
      </Button>
    </div>
  );
}

export default ReserveNowModalContent;
