import {getBlogsByDate} from '../../api/blog';
import {PAGE_SIZE} from '../../config/env';
import {addBlogsById} from '../slices/blog';
import {
  setBlogArchivesLoading,
  setBlogArchivesError,
  appendBlogsByYearMonth,
  setBlogArchivesCurPage,
  setBlogArchivesHasNext,
} from '../slices/blog-archive';

export const getBlogArchives =
  ({YM, startDT, endDT}) =>
  async (dispatch, getState) => {
    try {
      await dispatch(setBlogArchivesLoading({YM, loading: true}));
      await dispatch(setBlogArchivesError({YM, error: ''}));
      const {blogArchive} = getState();
      const {curPage} = blogArchive.byYM[YM];
      const blogs = await getBlogsByDate({
        startDateTime: startDT,
        endDateTime: endDT,
        _start: curPage * PAGE_SIZE,
        _limit: PAGE_SIZE,
      });

      if (blogs?.length) {
        await dispatch(addBlogsById(blogs));
        await dispatch(appendBlogsByYearMonth({YM, blogs}));
        await dispatch(setBlogArchivesCurPage({YM, curPage: curPage + 1}));
      }
      if (blogs?.length !== PAGE_SIZE) {
        await dispatch(setBlogArchivesHasNext({YM, hasNext: false}));
      }
    } catch (e) {
      // console.log(e);
      dispatch(
        setBlogArchivesError({
          YM,
          error: 'Failed to fetch the blog archives',
        })
      );
    } finally {
      dispatch(setBlogArchivesLoading({YM, loading: false}));
    }
  };
