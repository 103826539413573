import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';
import Slider from 'react-slick';
import ModalGallerySliderItem from './ModalGallerySliderItem';
import SliderNextArrow from './SliderNextArrow';
import SliderPreviousArrow from './SliderPreviousArrow';

const useStyles = makeStyles(() => ({
  slider: {
    '& .slick-slide': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

function ModalGallerySlider({slides, currentSlide}, ref) {
  const classes = useStyles();
  const sliderRef = React.useRef(null);

  React.useImperativeHandle(
    ref,
    () => ({
      goToSlide: slide => {
        sliderRef.current.slickGoTo(slide, true);
      },
    }),
    [sliderRef]
  );

  return (
    <Slider
      {...{
        arrows: true,
        draggable: false,
        slidesToScroll: 1,
        slidesToShow: 1,
        lazyLoad: true,
        initialSlide: currentSlide,
        fade: true,
        speed: 100,
        nextArrow: <SliderNextArrow />,
        prevArrow: <SliderPreviousArrow />,
      }}
      ref={sliderRef}
      className={classes.slider}
    >
      {slides.map((slide, index) => (
        <ModalGallerySliderItem key={`${slide.url + index}`} index={index} url={slide.url} />
      ))}
    </Slider>
  );
}

ModalGallerySlider.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      thumbnailUrl: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentSlide: PropTypes.number.isRequired,
};

export default React.forwardRef(ModalGallerySlider);
