import React from 'react';
import {useMediaQuery} from '@material-ui/core';
import debounce from '../../../utils/debounce';

export const useBlogParallax = () => {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const contentContainerRef = React.useRef();

  const resizeHandler = React.useMemo(
    () =>
      debounce(() => {
        if (contentContainerRef.current) {
          if (!isSmallScreen) {
            contentContainerRef.current.style.marginBottom = `-${
              contentContainerRef.current.clientHeight / 2
            }px`;
          } else {
            contentContainerRef.current.style.marginBottom = '0';
          }
        }
      }),
    [contentContainerRef, isSmallScreen]
  );

  const parallaxScrollingHandler = React.useCallback(() => {
    if (contentContainerRef.current) {
      const elemTop = contentContainerRef.current.getBoundingClientRect().top;
      const offsetY = window.pageYOffset;
      const translateY = 50 + 150 * (offsetY / (elemTop + offsetY));

      contentContainerRef.current.style.transform = `translateY(-${Math.min(120, translateY)}%)`;
    }
  }, [contentContainerRef]);

  /**
   * This hook will set the margin-bottom of the title section to 0 for mobile screens
   * and minus (-) half of its height for other breakpoints.
   */
  React.useEffect(() => {
    resizeHandler();

    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [resizeHandler]);

  /**
   * This hook will move the title section to the top or bottom based on scroll position.
   * And its disabled for the xs screens.
   */
  React.useEffect(() => {
    if (!isSmallScreen) {
      parallaxScrollingHandler();
      window.addEventListener('scroll', parallaxScrollingHandler);
    }
    return () => {
      if (!isSmallScreen) {
        window.removeEventListener('scroll', parallaxScrollingHandler);
      }
    };
  }, [parallaxScrollingHandler, isSmallScreen]);

  const getContainerProps = React.useCallback(
    () => ({
      ref: contentContainerRef,
    }),
    [contentContainerRef]
  );

  return {getContainerProps};
};

export default useBlogParallax;
