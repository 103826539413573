import React from 'react';
import {Box, Button, makeStyles, Typography} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  heading: {
    display: 'flex',
  },
  highlight: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    display: 'flex',
    alignItems: 'center',
    '&::before': {
      content: '""',
      display: 'block',
      width: '0.7em',
      height: '0.7em',
      backgroundColor: 'currentColor',
      borderRadius: '50%',
      marginRight: theme.spacing(1),
    },
  },
  time: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

function RestaurantWaitlistCard() {
  const classes = useStyles();

  return (
    <div className={classes.card}>
      <Box className={classes.heading}>
        <Typography component="div" className={classes.highlight}>
          Live wait time:
        </Typography>
        <Typography className={classes.time}>under 5 minutes</Typography>
      </Box>
      <Typography color="textSecondary">This restaurant might get busy</Typography>
      <Button size="large" fullWidth color="secondary" variant="contained">
        Join the waitlist
      </Button>
    </div>
  );
}

export default RestaurantWaitlistCard;
