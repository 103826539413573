import {Box, makeStyles, Typography} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import LandmarkCard from '../Cards/LandmarkCard';

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fit, minmax(${theme.typography.pxToRem(200)},1fr))`,
    gap: theme.spacing(2),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

function LandmarkSection({city}) {
  const classes = useStyles();
  const landmarks = useSelector(state => {
    if (city?._id) {
      return state.city.featuredLists.landMarks.byCity?.[city?._id];
    }
    return null;
  });
  return (
    <>
      <Box mb={2}>
        <Typography className={classes.title} variant="h5" component="h2">
          Nearby Landmarks
        </Typography>
      </Box>
      <div className={classes.grid}>
        {!!landmarks?.length &&
          landmarks.map(({_id, name, image}) => (
            <LandmarkCard key={_id} id={_id} name={name} image={image} />
          ))}
        {!landmarks?.length && (
          <>
            <LandmarkCard skeleton />
            <LandmarkCard skeleton />
            <LandmarkCard skeleton />
            <LandmarkCard skeleton />
            <LandmarkCard skeleton />
          </>
        )}
      </div>
    </>
  );
}

LandmarkSection.propTypes = {
  city: PropTypes.shape({_id: PropTypes.string.isRequired, cityName: PropTypes.string.isRequired}),
};

LandmarkSection.defaultProps = {
  city: null,
};

export default LandmarkSection;
