import {getBlogsByTag} from '../../api/blog';
import {PAGE_SIZE} from '../../config/env';
import {addBlogsById} from '../slices/blog';
import {
  appendBlogsByTag,
  setTagBlogsCurPage,
  setTagBlogsError,
  setTagBlogsHasNext,
  setTagBlogsLoading,
} from '../slices/tag-blog';

export const getTaggedBlogPosts =
  ({tag}) =>
  async (dispatch, getState) => {
    try {
      await dispatch(setTagBlogsLoading({tag, loading: true}));
      await dispatch(setTagBlogsError({tag, error: ''}));
      const {tagBlog} = getState();
      const {curPage} = tagBlog.byTag[tag];
      const blogs = await getBlogsByTag({
        tag,
        _start: curPage * PAGE_SIZE,
        _limit: PAGE_SIZE,
      });

      if (blogs?.length) {
        await dispatch(addBlogsById(blogs));
        await dispatch(appendBlogsByTag({tag, blogs}));
        await dispatch(setTagBlogsCurPage({tag, curPage: curPage + 1}));
      }
      if (blogs?.length !== PAGE_SIZE) {
        await dispatch(setTagBlogsHasNext({tag, hasNext: false}));
      }
    } catch (e) {
      // console.log(e);
      dispatch(
        setTagBlogsError({
          tag,
          error: 'Failed to fetch the tagged blog posts',
        })
      );
    } finally {
      dispatch(setTagBlogsLoading({tag, loading: false}));
    }
  };
