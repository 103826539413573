import {createSlice} from '@reduxjs/toolkit';

const homeSlice = createSlice({
  name: 'home',
  initialState: {
    featuredBlogs: {
      loading: true,
      error: '',
      byCity: {},
    },
  },
  reducers: {
    setHomeBlogsLoading(state, action) {
      state.featuredBlogs.loading = action.payload;
    },
    setHomeBlogsLoadingError(state, action) {
      state.featuredBlogs.error = action.payload;
    },
    addHomeFeaturedBlogsByCity: {
      reducer(state, action) {
        state.featuredBlogs.byCity[action.payload.cityName] = action.payload.blogs;
      },
      prepare({cityName, blogs}) {
        const blogIds = blogs.map(blog => blog.slug.current);
        return {payload: {cityName, blogs: blogIds}};
      },
    },
  },
});

export const {setHomeBlogsLoading, setHomeBlogsLoadingError, addHomeFeaturedBlogsByCity} =
  homeSlice.actions;

export default homeSlice.reducer;
