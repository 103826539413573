import React from 'react';
import PropTypes from 'prop-types';
import {Container, makeStyles} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import BlockContent from '@sanity/block-content-to-react';
import {sanity} from 'lib/sanity';

const useStyles = makeStyles(theme => ({
  root: {
    '& p': {
      ...theme.typography.body1,
      marginTop: 0,
      marginBottom: theme.spacing(2),
    },
    '& figure': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      '& img': {
        width: '100%',
        maxWidth: '100%',
      },
    },
  },
}));

/**
 * This component will parse (markdown to html) & show the body of the blogpost
 */
function BlogContentSection({body, skeleton}) {
  const classes = useStyles();
  return (
    <Container maxWidth="md">
      {skeleton ? (
        <>
          <Skeleton width="100%" />
          <Skeleton width="96%" />
          <Skeleton width="99%" />
          <Skeleton width="87%" />
        </>
      ) : (
        <div className={classes.root}>
          <BlockContent
            blocks={body}
            projectId={sanity.config().projectId}
            dataset={sanity.config().dataset}
          />
        </div>
      )}
    </Container>
  );
}

BlogContentSection.propTypes = {
  /**
   * body of the blogpost in the markdown (md) format
   */
  // eslint-disable-next-line react/forbid-prop-types
  body: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        _type: PropTypes.string.isRequired,
      })
    ),
    PropTypes.shape({
      _type: PropTypes.string.isRequired,
    }),
  ]),
  /**
   * set this value to true if you want to show the loading indicator
   */
  skeleton: PropTypes.bool,
};

BlogContentSection.defaultProps = {
  body: null,
  skeleton: false,
};

export default BlogContentSection;
