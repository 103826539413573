import moment from 'moment';

const DEFAULT_LABEL = 'Miscellaneous';

/**
 * @param {require('moment').MomentInput} date
 * @param {Object[]} slots
 * @param {Object} slots.date
 * @param {require('moment').MomentInput} slots.date.start
 * @param {Object[]} weeklySchedule
 * @param {number} weeklySchedule.dayNo
 * @param {Object[]} weeklySchedule.hours
 * @param {string} weeklySchedule.hours.title
 * @param {number} weeklySchedule.hours.from
 * @param {number} weeklySchedule.hours.additionTo
 * @param {Object[]} weeklySchedule.additionalHours
 * @param {string} weeklySchedule.additionalHours.title
 * @param {number} weeklySchedule.additionalHours.from
 * @param {number} weeklySchedule.additionalHours.additionTo
 * @returns {Array[]}
 */
function getLabeledSlots(date, slots, weeklySchedule) {
  if (!date || !slots?.length || !weeklySchedule?.length) {
    return [];
  }
  const dayNumber = moment.utc(date).isoWeekday();
  const daySchedule = weeklySchedule.find(schedule => schedule.dayNo === dayNumber);
  const labeledSlots = {};

  if (daySchedule?.hours?.length || daySchedule?.additionalHours?.length) {
    const $weeklySchedule = [].concat(daySchedule?.hours || [], daySchedule?.additionalHours || []);

    for (let i = 0; i < slots.length; i += 1) {
      const slot = slots[i];
      for (let j = 0; j < $weeklySchedule.length; j += 1) {
        const schedule = $weeklySchedule[j];
        const startTime = moment.utc(date).startOf('day').add(schedule.from, 'hour');
        const endTime = moment.utc(startTime).add(schedule.additionTo, 'hour');
        const slotTime = moment.utc(slot.date.start);

        if (startTime.isSameOrBefore(slotTime) && endTime.isSameOrAfter(slotTime)) {
          if (!labeledSlots[schedule.title]) {
            labeledSlots[schedule.title] = [];
          }
          labeledSlots[schedule.title].push(slot);

          break;
        } else if (j === $weeklySchedule.length - 1) {
          if (!labeledSlots[DEFAULT_LABEL]) {
            labeledSlots[DEFAULT_LABEL] = [];
          }
          labeledSlots[DEFAULT_LABEL].push(slot);
        }
      }
    }
  } else {
    labeledSlots[DEFAULT_LABEL] = slots;
  }
  return Object.entries(labeledSlots);
}

export {getLabeledSlots};
