import {sanity} from 'lib/sanity';

export function getBlogsByCity({cityName, _start = 0, _limit = 5}) {
  return sanity.fetch(
    `*[_type == "post" && city._ref in *[_type=="city" && name == $cityName]._id][$start...$limit] | order(_createdAt desc) {
      title,
      _id,
      slug,
      body,
      _createdAt,
      mainImage{
        asset->{
        _id,
        url
      }},
      author->,
      category->,
      city->,
      tags[]->
    }`,
    {
      cityName,
      start: _start,
      limit: _limit + _start,
    }
  );
}

export function getBlogById(id) {
  return sanity.fetch(
    `*[_type == "post" && slug.current == $blogId][0] {
      title,
      _id,
      slug,
      body,
      _createdAt,
      mainImage{
        asset->{
        _id,
        url
      }},
      author->,
      category->,
      city->,
      tags[]->
    }`,
    {
      blogId: id,
    }
  );
}

export function getBlogsByAuthor({authorId, _start = 0, _limit = 10}) {
  return sanity.fetch(
    `*[_type == "post" && author._ref in *[_type=="author" && slug.current == $authorId]._id][$start...$limit] | order(_createdAt desc) {
      title,
      _id,
      slug,
      body,
      _createdAt,
      mainImage{
        asset->{
        _id,
        url
      }},
      author->,
      category->,
      city->,
      tags[]->
    }`,
    {
      authorId,
      start: _start,
      limit: _limit + _start,
    }
  );
}

export function getBlogsByDate({startDateTime, endDateTime, _start = 0, _limit = 10}) {
  return sanity.fetch(
    `*[_type == "post" && dateTime(_createdAt) >= dateTime($startDateTime) && dateTime(_createdAt) <= dateTime($endDateTime)][$start...$limit] | order(_createdAt desc) {
      title,
      _id,
      slug,
      body,
      _createdAt,
      mainImage{
        asset->{
        _id,
        url
      }},
      author->,
      category->,
      city->,
      tags[]->
    }`,
    {
      startDateTime,
      endDateTime,
      start: _start,
      limit: _limit + _start,
    }
  );
}

export function getBlogsByTag({tag, _start = 0, _limit = 10}) {
  return sanity.fetch(
    `*[_type == "post" && references(*[_type=="tag" && slug.current == $tag]._id)][$start...$limit] | order(_createdAt desc) {
    title,
    _id,
    slug,
    body,
    _createdAt,
    mainImage{
      asset->{
      _id,
      url
    }},
    author->,
    category->,
    city->,
    tags[]->
  }`,
    {
      tag,
      start: _start,
      limit: _limit + _start,
    }
  );
}

export function getBlogsByCategory({category, _start = 0, _limit = 10}) {
  return sanity.fetch(
    `*[_type == "post" && category._ref in *[_type=="category" && slug.current == $category]._id][$start...$limit] | order(_createdAt desc) {
      _id,
      title,
      slug,
      body,
      _createdAt,
      mainImage{
        asset->{
        _id,
        url
      }},
      author->,
      category->,
      city->,
      tags[]->
    }`,
    {
      category,
      start: _start,
      limit: _limit + _start,
    }
  );
}
