import React from 'react';
import {useParams} from 'react-router-dom';
import {makeStyles, Button, CircularProgress} from '@material-ui/core';
import {useLazyGetSlotsByRestaurantIdQuery} from 'redux/query/server';
import {useDispatch} from 'react-redux';
import {setReservationModalOpen} from 'redux/slices/reservation';
import PartySizeInput from '../Input/PartySizeInput';
import ReservationDateInput from '../Input/ReservationDateInput';
import ReservationModal from '../Modal/ReservationModal/ReservationModal';
import {useSetupPartySizeAndDateRange} from '../hooks/useSetupPartySizeAndDateRange';
import {useRestaurantDetails} from '../hooks/useRestaurantDetails';
import {useCalculateSlots} from '../hooks/useCalculateSlots';

const useStyles = makeStyles(() => ({
  card: {
    '& > * + *': {
      marginTop: '2rem',
    },
  },
}));

function RestaurantSlotsSearchCard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {id} = useParams();
  const [fetchSlots, {isLoading, isSuccess, data}] = useLazyGetSlotsByRestaurantIdQuery({
    skip: !id,
    pollingInterval: 1000 * 60 * 5,
  });
  const {
    data: {settings},
    skeleton,
  } = useRestaurantDetails();

  useSetupPartySizeAndDateRange(settings);
  const calculateSlots = useCalculateSlots();

  React.useEffect(() => {
    if (isSuccess) {
      calculateSlots(data);
    }
  }, [isSuccess, data, calculateSlots]);

  return (
    <>
      <ReservationModal />
      <div className={classes.card}>
        <PartySizeInput />
        <ReservationDateInput />
        <Button
          onClick={() => {
            fetchSlots(id);
            dispatch(setReservationModalOpen(true));
          }}
          fullWidth
          color="primary"
          size="large"
          variant="contained"
          disabled={skeleton || isLoading}
          endIcon={isLoading ? <CircularProgress size="1rem" /> : null}
        >
          Search
        </Button>
      </div>
    </>
  );
}

export default RestaurantSlotsSearchCard;
