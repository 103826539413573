import {createSlice} from '@reduxjs/toolkit';

const INITIAL_BLOGS_DATA = {
  data: [],
  error: '',
  loading: true,
  curPage: 0,
  hasNext: true,
};

const categoryBlogSlice = createSlice({
  name: 'category-blog',
  initialState: {
    byCategory: {},
  },
  reducers: {
    prepareInitialCategoryBlogsState(state, action) {
      if (!state.byCategory[action.payload]) {
        state.byCategory[action.payload] = INITIAL_BLOGS_DATA;
      }
    },
    appendBlogsByCategory: {
      reducer(state, action) {
        state.byCategory[action.payload.category].data.push(...action.payload.blogs);
      },
      prepare({category, blogs}) {
        const blogIds = blogs.map(blog => blog.slug.current);
        return {payload: {category, blogs: blogIds}};
      },
    },
    setCategoryBlogsCurPage(state, action) {
      state.byCategory[action.payload.category].curPage = action.payload.curPage;
    },
    setCategoryBlogsHasNext(state, action) {
      state.byCategory[action.payload.category].hasNext = action.payload.hasNext;
    },
    setCategoryBlogsLoading(state, action) {
      state.byCategory[action.payload.category].loading = action.payload.loading;
    },
    setCategoryBlogsError(state, action) {
      state.byCategory[action.payload.category].error = action.payload.error;
    },
  },
});

export const {
  prepareInitialCategoryBlogsState,
  appendBlogsByCategory,
  setCategoryBlogsCurPage,
  setCategoryBlogsHasNext,
  setCategoryBlogsLoading,
  setCategoryBlogsError,
} = categoryBlogSlice.actions;

export default categoryBlogSlice.reducer;
