import {makeStyles} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import RestaurantCriticReview from './RestaurantCriticReview';

const useStyles = makeStyles(theme => ({
  container: {position: 'relative'},
  reviewsContainer: ({height, show, isValidHeight}) => ({
    height: isValidHeight && !show ? theme.typography.pxToRem(height) : 'auto',
    overflow: 'hidden',
    '& > *:not(:last-child)': {
      marginBottom: '1rem',
    },
  }),
  buttonContainer: ({show, isValidHeight}) => ({
    position: show ? 'static' : 'absolute',
    background: show ? 'transparent' : 'linear-gradient(transparent, #fafafa)',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: '1rem',
    display: isValidHeight ? 'flex' : 'none',
    justifyContent: 'center',
  }),
  button: {
    background: theme.palette.secondary.main,
    border: 'none',
    borderRadius: '1rem',
    padding: '0.5em 0.8em',
    textAlign: 'center',
    margin: 0,
    cursor: 'pointer',
    color: theme.palette.common.white,
    transition: `background ${theme.transitions.duration.standard} ${theme.transitions.easing.easeOut}`,
    '&:hover, &:focus': {
      background: theme.palette.secondary.light,
    },
  },
}));

function ShowOrHideRestaurantCriticReviews({reviews}) {
  const [show, setShow] = React.useState(false);
  const [height, setHeight] = React.useState(100);
  const [isValidHeight, setIsValidHeight] = React.useState(true);
  const classes = useStyles({height, show, isValidHeight});
  const reviewsContainerRef = React.useRef(null);

  React.useLayoutEffect(() => {
    const reviewsElm = reviewsContainerRef.current;

    let initialHeight = 0;
    for (let i = 0; i < 2; i += 1) {
      initialHeight += reviewsElm.children?.[i]?.offsetHeight ?? 0;
    }
    setHeight(initialHeight);

    if (reviewsElm.scrollHeight - initialHeight < 100) {
      setIsValidHeight(false);
    }
  }, [reviewsContainerRef]);

  return (
    <div className={classes.container}>
      <div className={classes.reviewsContainer} ref={reviewsContainerRef}>
        {reviews.map(review => (
          <RestaurantCriticReview
            key={review.excerpt}
            excerpt={review.excerpt}
            urlName={review.urlName}
            url={review.url}
          />
        ))}
      </div>

      <div className={classes.buttonContainer}>
        <button
          className={classes.button}
          onClick={() => {
            setShow(prevShow => !prevShow);
          }}
          type="button"
        >
          {show ? 'Show Less' : 'Show More'}
        </button>
      </div>
    </div>
  );
}

ShowOrHideRestaurantCriticReviews.propTypes = {
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      excerpt: PropTypes.string,
      urlName: PropTypes.string,
      url: PropTypes.string,
    })
  ).isRequired,
};

export default ShowOrHideRestaurantCriticReviews;
