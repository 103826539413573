import React from 'react';
import PropTypes from 'prop-types';
import {IconButton, Dialog, DialogContent, TextField, makeStyles, fade} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Fade from '../../Transitions/Fade';

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: fade('#ffffff', 0.95),
  },
  dialogHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px`,
    backgroundColor: '#fff',
  },
  search: {
    flex: '1 1 100%',
  },
  searchRoot: {
    fontSize: '2rem',
  },
}));

function SearchModal({open, onClose, children}) {
  const classes = useStyles();
  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      TransitionComponent={Fade}
      keepMounted
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullScreen
    >
      <div className={classes.dialogHeader}>
        <TextField
          InputProps={{
            classes: {
              root: classes.searchRoot,
            },
          }}
          className={classes.search}
          autoFocus
          placeholder="Search"
        />
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}

SearchModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default SearchModal;
