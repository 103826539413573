import {useDispatch, useSelector} from 'react-redux';
import {
  reservationDateSelect,
  reservationPartySizeCurrentSelect,
  setReservationSlots,
} from 'redux/slices/reservation';
import React from 'react';
import {getAvailableSlots} from '../utils/getAvailableSlots';
import {getLabeledSlots} from '../utils/getLabeledSlots';
import {useRestaurantDetails} from './useRestaurantDetails';

function useCalculateSlots() {
  const dispatch = useDispatch();

  const {
    data: {days, settings},
  } = useRestaurantDetails();
  const {current: reservationDate} = useSelector(reservationDateSelect);
  const partySize = useSelector(reservationPartySizeCurrentSelect);
  const partySizeWiseSlots = settings?.partySizeViseSlots;

  return React.useCallback(
    rawSlots => {
      const calcSlots = getAvailableSlots(reservationDate, partySize, partySizeWiseSlots, rawSlots);
      const labeledSlots = getLabeledSlots(reservationDate, calcSlots, days);
      dispatch(setReservationSlots(labeledSlots));
    },
    [partySize, reservationDate, dispatch, partySizeWiseSlots, days]
  );
}

export {useCalculateSlots};
