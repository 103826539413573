import React from 'react';
import {useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Layout from '../components/Layout';
import BlogContainer from '../components/Blog/BlogContainer';

/**
 * A component for the blog post page
 */
function Blog() {
  const {id} = useParams();

  return (
    <Layout>
      <Helmet>
        <title>Blog | Khaaba</title>
      </Helmet>
      <BlogContainer id={id} />
    </Layout>
  );
}

export default Blog;
