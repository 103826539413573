import {createSlice} from '@reduxjs/toolkit';

const INITIAL_BLOGS_DATA = {
  data: [],
  error: '',
  loading: true,
  curPage: 0,
  hasNext: true,
};

const cityBlogSlice = createSlice({
  name: 'city-blog',
  initialState: {},
  reducers: {
    prepareInitialCityBlogsState(state, action) {
      if (!state[action.payload]) {
        state[action.payload] = INITIAL_BLOGS_DATA;
      }
    },
    appendBlogsByCity: {
      reducer(state, action) {
        state[action.payload.city].data.push(...action.payload.blogs);
      },
      prepare({city, blogs}) {
        const blogIds = blogs.map(blog => blog.slug.current);
        return {payload: {city, blogs: blogIds}};
      },
    },
    setCityBlogsCurPage(state, action) {
      state[action.payload.city].curPage = action.payload.curPage;
    },
    setCityBlogsHasNext(state, action) {
      state[action.payload.city].hasNext = action.payload.hasNext;
    },
    setCityBlogsLoading(state, action) {
      state[action.payload.city].loading = action.payload.loading;
    },
    setCityBlogsError(state, action) {
      state[action.payload.city].error = action.payload.error;
    },
  },
});

export const {
  prepareInitialCityBlogsState,
  appendBlogsByCity,
  setCityBlogsCurPage,
  setCityBlogsHasNext,
  setCityBlogsLoading,
  setCityBlogsError,
} = cityBlogSlice.actions;

export default cityBlogSlice.reducer;
