import React from 'react';
import {useDispatch} from 'react-redux';
import {Redirect, Route} from 'react-router-dom';
import moment from 'moment';
import {getAllCities} from './redux/thunks/getAllCities';
import Home from './pages/Home';
import Blog from './pages/Blog';
import AuthorBlogs from './pages/AuthorBlogs';
import BlogArchives from './pages/BlogArchives';
import TaggedBlogs from './pages/TaggedBlogs';
import CategoryBlogs from './pages/CategoryBlogs';
import CityBlogs from './pages/CityBlogs';
import RestaurantProfile from './pages/RestaurantProfile';

/**
 * This component will fetch the initial data (i.e all cities...)
 * and setup route for each page.
 */
const App = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getAllCities());
  }, [dispatch]);

  return (
    <div className="App">
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/blog/author/:author">
        <AuthorBlogs />
      </Route>
      <Route exact path="/blog/category/:category">
        <CategoryBlogs />
      </Route>
      <Route exact path="/blog/city/:city">
        <CityBlogs />
      </Route>
      <Route exact path="/blog/archive/">
        <Redirect
          to={`/blog/archive/${moment.utc().get('year')}/${moment.utc().get('month') + 1}`}
        />
      </Route>
      <Route exact path="/blog/archive/:year/:month">
        <BlogArchives />
      </Route>
      <Route exact path="/blog/tag/:tag">
        <TaggedBlogs />
      </Route>
      <Route exact path="/blog/:id">
        <Blog />
      </Route>
      <Route exact path="/restaurant/:id">
        <RestaurantProfile />
      </Route>
    </div>
  );
};

export default App;
