import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query/react';

import home from './slices/home';
import blog from './slices/blog';
import city from './slices/city';
import authorBlog from './slices/author-blog';
import categoryBlog from './slices/category-blog';
import cityBlog from './slices/city-blog';
import tagBlog from './slices/tag-blog';
import blogArchive from './slices/blog-archive';
import reservation from './slices/reservation';
import {serverApi} from './query/server';

export const reducer = combineReducers({
  home,
  blog,
  city,
  authorBlog,
  categoryBlog,
  cityBlog,
  tagBlog,
  blogArchive,
  reservation,
  [serverApi.reducerPath]: serverApi.reducer,
});

export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(serverApi.middleware),
});

setupListeners(store.dispatch);

export default store;
