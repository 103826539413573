import {getBlogsByCity} from '../../api/blog';
import {addBlogsById} from '../slices/blog';
import {
  setHomeBlogsLoading,
  addHomeFeaturedBlogsByCity,
  setHomeBlogsLoadingError,
} from '../slices/home';

export const getFeaturedHomeBlogs =
  ({cityName}) =>
  async (dispatch, getState) => {
    try {
      dispatch(setHomeBlogsLoading(true));
      dispatch(setHomeBlogsLoadingError(''));

      if (!cityName?.trim()) {
        throw new Error();
      }

      const state = getState();
      // check if blogs already exists
      const blogIds = state.home.featuredBlogs.byCity[cityName];
      const prevBlogs = blogIds?.map(id => state.blog.byId[id]);
      // if blogs does not exist locally then fetch from server
      if (!(blogIds?.length && prevBlogs?.length && blogIds.length === prevBlogs.length)) {
        const blogs = await getBlogsByCity({cityName});
        if (blogs?.length) {
          dispatch(addHomeFeaturedBlogsByCity({cityName, blogs}));
          dispatch(addBlogsById(blogs));
        } else {
          throw new Error('Blogs must be exactly five');
        }
      }
    } catch (e) {
      // console.log(e);
      dispatch(setHomeBlogsLoadingError('Failed to fetch the featured blogs'));
    } finally {
      dispatch(setHomeBlogsLoading(false));
    }
  };
