import React from 'react';
import {Typography, Box, Button, makeStyles} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import PartySizeInput from 'components/RestaurantProfile/Input/PartySizeInput';
import ReservationCalendarInput from 'components/RestaurantProfile/Input/ReservationCalendarInput';
import LoginButton from 'components/Buttons/Auth/LoginButton';
import moment from 'moment';
import {
  reservationSlotsSelect,
  RESERVATION_COMPLETE_MODAL_TYPE,
  setReservationCurrentSlot,
  setReservationModalType,
} from 'redux/slices/reservation';
import {useAuth0} from '@auth0/auth0-react';

const useStyles = makeStyles(theme => ({
  reservationSlotsGrid: {
    gap: '0.8rem 1rem',
    display: 'grid',
    'grid-template-columns': `repeat(auto-fill, minmax(${theme.typography.pxToRem(100)}, 1fr))`,
  },
  slotsSection: {
    '& > * + *': {
      marginTop: '1rem',
    },
  },
  slotsLabel: {
    marginBottom: '1rem',
    textTransform: 'uppercase',
  },
}));

// eslint-disable-next-line react/prop-types
function ReservationSlotButton({time, onClick}) {
  const {isAuthenticated} = useAuth0();

  const SlotButton = isAuthenticated ? Button : LoginButton;

  return (
    <SlotButton color="primary" variant="contained" onClick={onClick}>
      <Typography variant="subtitle2">{time}</Typography>
    </SlotButton>
  );
}

function FindSlotsModalContent() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {isAuthenticated} = useAuth0();
  const slots = useSelector(reservationSlotsSelect);

  return (
    <>
      <Box mb={4}>
        <PartySizeInput />
      </Box>
      <ReservationCalendarInput />

      <div className={classes.slotsSection}>
        {!slots?.length && <Typography color="textSecondary">No Slots Found</Typography>}
        {Boolean(slots?.length) &&
          slots.map(([label, $slots]) => (
            <>
              <Typography className={classes.slotsLabel} variant="subtitle2" color="textSecondary">
                {label}
              </Typography>
              <div className={classes.reservationSlotsGrid}>
                {$slots.map(slot => (
                  <ReservationSlotButton
                    onClick={() => {
                      if (!isAuthenticated) {
                        return;
                      }
                      dispatch(setReservationCurrentSlot(slot));
                      dispatch(setReservationModalType(RESERVATION_COMPLETE_MODAL_TYPE));
                    }}
                    key={slot.date.start}
                    time={moment(slot.date.start).format('hh:mm A')}
                  />
                ))}
              </div>
            </>
          ))}
      </div>
    </>
  );
}

export default FindSlotsModalContent;
