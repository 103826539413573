import {MIN_SLOT_DURATION_IN_MINUTES} from 'config/constants';
import moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD';

/**
 *
 * @param {require('moment').MomentInput} date - in local timezone of the user
 * @param {number} partySize
 * @param {Object[]} partySizeWiseSlots
 * @param {number} settings.partySizeWiseSlots[].slotsAllocation
 * @param {number} settings.partySizeWiseSlots[].partySize
 * @param {Object[]} rawSlots
 * @param {require('moment').MomentInput} rawSlots[].date
 * @param {Object[]} rawSlots[].slots
 * @param {require('moment').MomentInput} rawSlots[].slots[].time
 * @param {Object[]} rawSlots[].slots[].freeTableIDs
 * @param {string} rawSlots[].slots[].freeTableIDs[]._id
 * @param {number} rawSlots[].slots[].freeTableIDs[].capacityMin
 * @param {number} rawSlots[].slots[].freeTableIDs[].capacityMax
 */
function getAvailableSlots(date, partySize, partySizeWiseSlots, rawSlots) {
  try {
    if (!date || !partySize || !partySizeWiseSlots?.length || !rawSlots?.length) {
      return [];
    }
    const utcSelectedDate = moment.utc(date);
    const isToday = utcSelectedDate.format(DATE_FORMAT) === moment.utc().format(DATE_FORMAT);
    const slotUnitsForPartySize = partySizeWiseSlots.find(data => data.partySize === partySize);
    const utcSelectedDateRawSlots = rawSlots.find(
      slots => slots.date === utcSelectedDate.format(DATE_FORMAT)
    );

    let filteredRawSlots = [...utcSelectedDateRawSlots.slots];

    if (isToday) {
      const minSlotTimeFromNow = moment.utc(utcSelectedDate).add(1, 'hours');
      filteredRawSlots = utcSelectedDateRawSlots.slots.filter(slot =>
        moment.utc(slot.time).isSameOrAfter(minSlotTimeFromNow)
      );
    }

    const reservationSlots = [];

    for (let i = 0; i <= filteredRawSlots.length - slotUnitsForPartySize.slotsAllocation; i += 1) {
      const currentRawSlot = filteredRawSlots[i];
      const newReservationSlot = {
        date: {start: currentRawSlot.time, end: null},
        freeTableIDs: null,
      };
      const freeTableIDs = new Set();
      for (let j = i; j < i + slotUnitsForPartySize.slotsAllocation; j += 1) {
        const currentInnerRawSlot = filteredRawSlots[j];
        const filteredFreeTableIDs = currentInnerRawSlot.freeTableIDs
          .filter(table => partySize >= table.capacityMin && partySize <= table.capacityMax)
          .map(table => table._id);
        if (!filteredFreeTableIDs?.length) {
          break;
        }
        const isFirstSlotUnit = i === j;
        const isLastSlotUnit = j === i + slotUnitsForPartySize.slotsAllocation - 1;

        if (isFirstSlotUnit) {
          filteredFreeTableIDs.forEach(id => freeTableIDs.add(id));
        }

        if (!isFirstSlotUnit) {
          const innerFreeTableIDs = [...freeTableIDs];
          innerFreeTableIDs.forEach(id => {
            if (!filteredFreeTableIDs.includes(id)) {
              freeTableIDs.delete(id);
            }
          });
          if (!freeTableIDs.size) {
            break;
          }
        }
        if (isLastSlotUnit) {
          newReservationSlot.date.end = moment
            .utc(currentInnerRawSlot.time)
            .add(MIN_SLOT_DURATION_IN_MINUTES, 'minutes')
            .format();
          newReservationSlot.freeTableIDs = [...freeTableIDs];
          reservationSlots.push(newReservationSlot);
        }
      }
      freeTableIDs.clear();
    }
    return reservationSlots;
  } catch {
    return [];
  }
}

export {getAvailableSlots};
