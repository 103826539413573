import React from 'react';
import PropTypes from 'prop-types';
import {Container} from '@material-ui/core';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import GallerySliderItem from './GallerySliderItem';

function SampleNextArrow(props) {
  // eslint-disable-next-line react/prop-types
  const {className, style, currentSlide, slideCount, onClick} = props;
  return (
    <button
      disabled={currentSlide + 1 === slideCount}
      className={className}
      type="button"
      aria-label="Next"
      style={{...style, display: 'block', right: '1rem', zIndex: 20}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  // eslint-disable-next-line react/prop-types
  const {className, style, currentSlide, onClick} = props;
  return (
    <button
      disabled={currentSlide === 0}
      className={className}
      type="button"
      aria-label="Previous"
      style={{...style, display: 'block', left: '1rem', zIndex: 20}}
      onClick={onClick}
    />
  );
}

const settings = {
  speed: 500,
  dots: false,
  arrows: true,
  infinite: true,
  draggable: false,
  adaptiveHeight: true,
  variableWidth: true,
  slidesToScroll: 1,
  slidesToShow: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

function GallerySlider({slides, onSlideClick}) {
  return (
    <Container style={{padding: 0}}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <GallerySliderItem
            key={`${slide.url + index}`}
            onClick={onSlideClick}
            index={index}
            url={slide.url}
          />
        ))}
      </Slider>
    </Container>
  );
}

GallerySlider.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      thumbnailUrl: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSlideClick: PropTypes.func.isRequired,
};

export default GallerySlider;
