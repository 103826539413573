import React from 'react';
import {DatePicker} from '@material-ui/pickers';
import {useDispatch, useSelector} from 'react-redux';
import {reservationDateSelect, setReservationDateCurrent} from 'redux/slices/reservation';
import {useRestaurantDetails} from '../hooks/useRestaurantDetails';

function ReservationDateInput() {
  const dispatch = useDispatch();
  const {skeleton: disabled} = useRestaurantDetails();
  const {min: minDate, max: maxDate, current: value} = useSelector(reservationDateSelect);
  const onChange = React.useCallback(
    newDate => {
      dispatch(setReservationDateCurrent(newDate.utc().format()));
    },
    [dispatch]
  );

  return (
    <DatePicker
      value={value}
      onChange={onChange}
      variant="inline"
      format="MMM D, YYYY"
      // disablePast
      minDate={minDate}
      maxDate={maxDate}
      fullWidth
      disabled={disabled}
      inputVariant="outlined"
    />
  );
}

export default ReservationDateInput;
