import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Box,
  ListItemIcon,
  ListItemText,
  Typography,
  Toolbar,
  AppBar,
  Container,
  Link as MuiLink,
} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';
import {Skeleton} from '@material-ui/lab';
import clsx from 'clsx';
import {useAuth0} from '@auth0/auth0-react';
import LoginButton from 'components/Buttons/Auth/LoginButton';
import SearchIcon from '@material-ui/icons/Search';
import CitySelector from './CitySelector';
import SearchModal from '../Modals/Search/SearchModal';
import CuisineColumnsContainer from '../Search/CuisineColumnsContainer';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  marginRightAllChild: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(3),
    },
  },
  searchModalContentContainer: {
    position: 'relative',
    top: '25%',
    paddingBottom: '2rem',
  },
}));

function Navbar() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const [isSearchOpen, setSearchOpen] = React.useState(false);
  const {isLoading, user, isAuthenticated, logout} = useAuth0();

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSearchClickOpen = () => {
    setSearchOpen(true);
  };
  const handleSearchClose = () => {
    setSearchOpen(false);
  };

  return (
    <AppBar position="fixed" variant="outlined" color="default">
      <Container>
        <Toolbar>
          <div className={clsx(classes.grow, classes.marginRightAllChild)}>
            <Typography variant="h5">
              <MuiLink color="inherit" underline="none" component={RouterLink} to="/">
                Khaaba
              </MuiLink>
            </Typography>
            <CitySelector />
            <IconButton onClick={handleSearchClickOpen}>
              <SearchIcon />
            </IconButton>
            <SearchModal open={isSearchOpen} onClose={handleSearchClose}>
              <Container className={classes.searchModalContentContainer} maxWidth="sm">
                <CuisineColumnsContainer />
              </Container>
            </SearchModal>
          </div>
          {isLoading && (
            <div>
              <Skeleton variant="circle" width={40} height={40} />
            </div>
          )}
          {!isAuthenticated && !isLoading && (
            <LoginButton color="primary" variant="contained">
              Join
            </LoginButton>
          )}
          {isAuthenticated && !isLoading && (
            <div>
              <IconButton
                size="small"
                aria-label="account of current user"
                aria-controls="user-menu"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Avatar alt={user.nickname} src={user.picture} />
              </IconButton>
              <Menu
                id="user-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                keepMounted
                open={isMenuOpen}
                onClose={handleMenuClose}
              >
                <MenuItem disabled divider style={{opacity: 1}}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <Box mr={2}>
                      <Avatar alt={user.nickname} src={user.picture} />
                    </Box>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography variant="h6" gutterBottom>
                        {user.nickname}
                      </Typography>
                    </div>
                  </div>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    logout();
                    handleMenuClose();
                  }}
                >
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
