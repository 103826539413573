import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Moment from 'react-moment';
import {Typography} from '@material-ui/core';

function HourRange({range}) {
  return (
    <Typography style={{marginLeft: '1rem', marginBottom: '0.5rem'}}>
      <Moment date={moment.tz('Asia/Karachi').startOf('day').add(range.from, 'hour')} format="LT" />{' '}
      -{' '}
      <Moment
        date={moment
          .tz('Asia/Karachi')
          .startOf('day')
          .add(range.from + range.additionTo, 'hour')}
        format="LT"
      />
    </Typography>
  );
}

HourRange.propTypes = {
  range: PropTypes.shape({
    from: PropTypes.number.isRequired,
    additionTo: PropTypes.number.isRequired,
  }).isRequired,
};

export default HourRange;
