import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  item: {
    cursor: 'pointer',
    background: 'none',
    padding: 0,
    border: 'none',
  },
}));

function GallerySliderItem({onClick: onSlideClick, index, url, ...props}) {
  const classes = useStyles();

  const onClick = React.useCallback(() => {
    onSlideClick(index);
  }, [index, onSlideClick]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <button {...props} type="button" className={classes.item} onClick={onClick}>
      <img
        src={url}
        alt={index}
        style={{
          objectFit: 'cover',
          height: '400px',
          width: 'auto',
          margin: 0,
        }}
      />
    </button>
  );
}

GallerySliderItem.propTypes = {
  index: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default GallerySliderItem;
