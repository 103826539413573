import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, Typography, Box} from '@material-ui/core';
import RestaurantCard from '../Cards/RestaurantCard';
import RestaurantGrid from './RestaurantGrid';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
    marginBottom: theme.typography.pxToRem(theme.spacing(1)),
  },
}));

/**
 * A template component for the featured restaurents section in the homepage.
 * It'll show the section title & its restaurants
 */
function FeaturedRestaurantsListContainer({title, description, restaurants, skeleton}) {
  const classes = useStyles();
  return (
    <>
      {!skeleton && (
        <Box mb={3}>
          <Typography className={classes.title} variant="h5" component="h2">
            {title}
          </Typography>
          <Typography variant="body1">{description}</Typography>
        </Box>
      )}
      <RestaurantGrid>
        {!skeleton &&
          restaurants.map(({_id, images, cuisineCategory, neighborhood, name}) => (
            <RestaurantCard
              key={_id}
              images={images}
              cuisineCategory={cuisineCategory}
              name={name}
              neighborhood={neighborhood}
              id={_id}
            />
          ))}
        {skeleton && [1, 2, 3, 4, 5].map(id => <RestaurantCard key={id} skeleton={skeleton} />)}
      </RestaurantGrid>
    </>
  );
}

FeaturedRestaurantsListContainer.propTypes = {
  /**
   * title of the section
   */
  title: PropTypes.string,
  /**
   * description of the section
   */
  description: PropTypes.string,
  /**
   * set it to true if you dont have the data & fetching is in progress
   */
  skeleton: PropTypes.bool,
  /**
   * An array of the restaurants
   */
  restaurants: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequirerd,
      name: PropTypes.string.isRequirerd,
      imageUrl: PropTypes.string.isRequirerd,
      cuisineCategory: PropTypes.string.isRequirerd,
      neighborhood: PropTypes.string.isRequirerd,
      skeleton: PropTypes.bool.isRequirerd,
    })
  ),
};

FeaturedRestaurantsListContainer.defaultProps = {
  title: null,
  skeleton: true,
  description: null,
  restaurants: null,
};

export default FeaturedRestaurantsListContainer;
