import React from 'react';
import PropTypes from 'prop-types';
import {Link, makeStyles, Typography} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  title: {
    fontStyle: 'italic',
    marginBottom: '0.4rem',
  },
  link: {
    display: 'inline-block',
  },
}));

function RestaurantCriticReview({excerpt, urlName, url, skeleton}) {
  const classes = useStyles();
  return (
    <div>
      <Typography className={classes.title}>
        {skeleton ? (
          <>
            <Skeleton width="90%" />
            <Skeleton width="95%" />
            <Skeleton width="40%" />
          </>
        ) : (
          excerpt
        )}
      </Typography>

      {!skeleton ? (
        <Link className={classes.link} href={url} target="_blank" rel="noopener noreferrer">
          {urlName}
        </Link>
      ) : (
        <Skeleton width={30} />
      )}
    </div>
  );
}

RestaurantCriticReview.propTypes = {
  excerpt: PropTypes.string,
  urlName: PropTypes.string,
  url: PropTypes.string,
  skeleton: PropTypes.bool,
};

RestaurantCriticReview.defaultProps = {
  excerpt: null,
  urlName: null,
  url: null,
  skeleton: false,
};

export default RestaurantCriticReview;
