import moment from 'moment';
import {combineWeekdays} from './combineWeekdays';

function getMenuSchedule(timings, timingsForProperty = 'hours') {
  if (!timings || !Array.isArray(timings?.[0]?.[timingsForProperty])) {
    return [];
  }
  const menuMap = {};
  for (let i = 0; i < timings.length; i += 1) {
    const dayTimings = timings[i];
    if (dayTimings.isOpen) {
      dayTimings[timingsForProperty].forEach(hour => {
        if (!menuMap[hour.title]) {
          menuMap[hour.title] = {};
        }
        const hoursMap = menuMap[hour.title];
        const startTime = moment
          .utc()
          .startOf('day')
          .add(hour.from, 'hour')
          .local()
          .format('hh:mm A');
        const endTime = moment
          .utc()
          .startOf('day')
          .add(hour.from + hour.additionTo, 'hour')
          .local()
          .format('hh:mm A');
        const combinedTime = `${startTime} - ${endTime}`;
        if (!hoursMap[combinedTime]) {
          hoursMap[combinedTime] = [];
        }
        const hoursMapDays = hoursMap[combinedTime];
        hoursMapDays.push(dayTimings.dayNo);
      });
    }
  }

  const menuMapKeys = Object.keys(menuMap);
  menuMapKeys.forEach(key => {
    const hoursMap = menuMap[key];
    const hoursMapKeys = Object.keys(hoursMap);
    hoursMapKeys.forEach(hKey => {
      hoursMap[hKey] = combineWeekdays(hoursMap[hKey]).join(', ');
    });
    menuMap[key] = Object.entries(menuMap[key]);
  });

  return Object.entries(menuMap);
}

export {getMenuSchedule};
