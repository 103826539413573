import React from 'react';
import {useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {useDispatch} from 'react-redux';
import {Container} from '@material-ui/core';
import Layout from '../components/Layout';
import {prepareInitialCityBlogsState} from '../redux/slices/city-blog';
import CityBlogsContainer from '../components/CityBlogs/CityBlogsContainer';

/**
 * A page that'll show the blog posts by a city
 */
function CityBlogs() {
  const {city} = useParams();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(prepareInitialCityBlogsState(city));
  }, [city, dispatch]);

  return (
    <Layout>
      <Helmet>
        <title>{city} Blogs | Khaaba</title>
      </Helmet>
      <Container maxWidth="md">
        <CityBlogsContainer city={city} />
      </Container>
    </Layout>
  );
}

export default CityBlogs;
