import React from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Helmet} from 'react-helmet';
import moment from 'moment';
import {Container} from '@material-ui/core';
import {prepareInitialBlogArchivesState} from '../redux/slices/blog-archive';
import Layout from '../components/Layout';
import BlogArchivesContainer from '../components/BlogArchives/BlogArchivesContainer';

/**
 * a page component that'll show the blog archives by month & year
 */
function BlogArchives() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {month, year} = useParams();

  React.useEffect(() => {
    const parsedYear = parseInt(year, 10);
    const parsedMonth = parseInt(month, 10);
    const dt = moment.utc(`${parsedYear} ${parsedMonth} 1`, 'YYYY M D', true);
    if (dt.isValid()) {
      const YM = dt.format('YYYY-M');
      const startDT = dt.startOf('month').format();
      const endDT = dt.endOf('month').format();
      // setup the initial state for the year & month pair
      dispatch(
        prepareInitialBlogArchivesState({
          year: parsedYear,
          month: parsedMonth,
          YM,
          startDT,
          endDT,
        })
      );
    } else {
      const curDateTime = moment.utc();
      history.replace(`/blog/archive/${curDateTime.format('YYYY')}/${curDateTime.format('M')}`);
    }
  }, [year, month, dispatch, history]);

  return (
    <Layout>
      <Helmet>
        <title>{`${year}-${month} Archive`} | Khaaba</title>
      </Helmet>
      <Container maxWidth="lg">
        <BlogArchivesContainer />
      </Container>
    </Layout>
  );
}

export default BlogArchives;
