import React from 'react';
import PropTypes from 'prop-types';
import {Divider, makeStyles, Typography, Zoom} from '@material-ui/core';
import debounce from 'utils/debounce';
import RestaurantSlotsSearchCard from '../Card/RestaurantSlotsSearchCard';
import RestaurantWaitlistCard from '../Card/RestaurantWaitlistCard';
import {useRestaurantDetails} from '../hooks/useRestaurantDetails';

const useStyles = makeStyles(theme => ({
  panel: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    '& > * + *': {
      marginTop: '2rem',
    },
  },
  stickyShow: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: '2rem',
    '& > * + *': {
      marginTop: '0.4rem',
    },
  },
}));

function StickyPanel({className}) {
  const classes = useStyles();
  const [isSticky, setIsSticky] = React.useState(false);
  const {
    data: {name, neighborhood, cuisineCategory},
    skeleton,
  } = useRestaurantDetails();

  const stickyRef = React.createRef();

  React.useEffect(() => {
    const cachedRef = stickyRef.current;

    const scrollHandler = debounce(() => {
      const topOffset = cachedRef.getBoundingClientRect().top;
      if (topOffset <= 80 && !isSticky) {
        setIsSticky(true);
      } else if (topOffset > 80 && isSticky) {
        setIsSticky(false);
      }
    }, 30);

    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [isSticky, stickyRef]);

  return (
    <div ref={stickyRef} className={className}>
      <div className={classes.panel}>
        {!skeleton && isSticky && (
          <Zoom in>
            <div className={classes.stickyShow}>
              <Typography variant="h6">{name}</Typography>
              <Typography variant="subtitle2">{`${neighborhood} · ${cuisineCategory}`}</Typography>
            </div>
          </Zoom>
        )}
        <RestaurantWaitlistCard />
        <Divider />
        <RestaurantSlotsSearchCard />
      </div>
    </div>
  );
}

StickyPanel.propTypes = {
  className: PropTypes.string.isRequired,
};

export default StickyPanel;
