import React from 'react';
import {AppBar, Toolbar, Typography, Box, IconButton} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {
  reservationModalTypeSelect,
  RESERVATION_COMPLETE_MODAL_TYPE,
  setReservationModalOpen,
  setReservationModalType,
  SLOTS_MODAL_TYPE,
} from 'redux/slices/reservation';
import {useRestaurantDetails} from 'components/RestaurantProfile/hooks/useRestaurantDetails';
import LoginButton from 'components/Buttons/Auth/LoginButton';
import CloseIcon from '@material-ui/icons/Close';
import {MdKeyboardArrowLeft} from 'react-icons/md';
import {useAuth0} from '@auth0/auth0-react';

function ReservationModalHeader() {
  const dispatch = useDispatch();
  const modalType = useSelector(reservationModalTypeSelect);
  const {isAuthenticated} = useAuth0();

  const {
    data: {name},
  } = useRestaurantDetails();

  return (
    <AppBar position="static" variant="outlined" color="default">
      <Toolbar>
        {modalType === SLOTS_MODAL_TYPE && (
          <Typography variant="h6" style={{flexGrow: 1}}>
            {name}
          </Typography>
        )}
        {modalType === RESERVATION_COMPLETE_MODAL_TYPE && (
          <Box flexGrow="1">
            <IconButton
              size="small"
              onClick={() => {
                dispatch(setReservationModalType(SLOTS_MODAL_TYPE));
              }}
            >
              <MdKeyboardArrowLeft fontSize="2.5rem" />
            </IconButton>
          </Box>
        )}
        {!isAuthenticated && (
          <Box mr={2}>
            <LoginButton variant="outlined" color="primary">
              Login
            </LoginButton>
          </Box>
        )}
        <IconButton
          onClick={() => dispatch(setReservationModalOpen(false))}
          edge="start"
          color="inherit"
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

export default ReservationModalHeader;
