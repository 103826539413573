import React from 'react';
import {makeStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import CuisineAccordion from './CuisineAccordion';
import CuisineAccordionSkeleton from './CuisineAccordionSkeleton';

const useStyles = makeStyles(theme => ({
  tabActive: {
    'background-color': theme.palette.action.hover,
  },
  gridList: {
    '& > *:nth-of-type(odd)': {
      paddingRight: '1rem !important',
      '& button': {
        justifyContent: 'flex-end',
      },
      '& ul li': {
        justifyContent: 'flex-end',
      },
    },
    '& > *:nth-of-type(even)': {
      paddingLeft: '1rem !important',
      '& button': {
        justifyContent: 'flex-start',
      },
    },
  },
  columns: {
    display: 'flex',
  },
  column: {
    flex: '1 1',
  },
}));

function CuisineColumns({skeleton, cuisines}) {
  const classes = useStyles();
  const [cuisineTab, setCuisineTab] = React.useState('');

  const [firstHalf, secondHalf] = React.useMemo(() => {
    if (cuisines?.length && !skeleton) {
      const half = Math.ceil(cuisines.length / 2);

      return [cuisines.slice(0, half), cuisines.slice(-half)];
    }
    return [null, null];
  }, [cuisines, skeleton]);

  return (
    <div className={classes.columns}>
      <div className={classes.column}>
        {!skeleton &&
          firstHalf.map(({title, restaurants}) => (
            <CuisineAccordion
              key={title}
              cuisine={title}
              onClick={() => {
                if (title === cuisineTab) {
                  setCuisineTab('');
                } else {
                  setCuisineTab(title);
                }
              }}
              restaurants={restaurants}
              selected={!!(title === cuisineTab)}
              side="left"
            />
          ))}
        {skeleton && (
          <>
            <CuisineAccordionSkeleton side="left" />
            <CuisineAccordionSkeleton side="left" />
            <CuisineAccordionSkeleton side="left" />
            <CuisineAccordionSkeleton side="left" />
            <CuisineAccordionSkeleton side="left" />
          </>
        )}
      </div>
      <div className={classes.column}>
        {!skeleton &&
          secondHalf.map(({title, restaurants}) => (
            <CuisineAccordion
              key={title}
              cuisine={title}
              onClick={() => {
                if (title === cuisineTab) {
                  setCuisineTab('');
                } else {
                  setCuisineTab(title);
                }
              }}
              restaurants={restaurants}
              selected={!!(title === cuisineTab)}
              side="right"
            />
          ))}
        {skeleton && (
          <>
            <CuisineAccordionSkeleton side="right" />
            <CuisineAccordionSkeleton side="right" />
            <CuisineAccordionSkeleton side="right" />
            <CuisineAccordionSkeleton side="right" />
            <CuisineAccordionSkeleton side="right" />
          </>
        )}
      </div>
    </div>
  );
}

CuisineColumns.propTypes = {
  skeleton: PropTypes.bool,
  cuisines: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      restaurants: PropTypes.arrayOf(
        PropTypes.shape({name: PropTypes.string.isRequired, _id: PropTypes.string.isRequired})
      ).isRequired,
    })
  ),
};

CuisineColumns.defaultProps = {
  skeleton: false,
  cuisines: null,
};

export default CuisineColumns;
