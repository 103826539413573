import {getAllCities as getAllCitiesApi} from '../../api/city';

import {setAllCities, setAllCitiesError, setAllCitiesLoading} from '../slices/city';

export const getAllCities = () => async dispatch => {
  try {
    await dispatch(setAllCitiesLoading(true));
    await dispatch(setAllCitiesError(''));

    const cities = await getAllCitiesApi();

    if (
      cities.status === 200 &&
      cities.data?.code === 200 &&
      cities.data?.data?.citiesList?.length
    ) {
      await dispatch(
        setAllCities({
          cities: cities.data.data.citiesList,
          // Select the first city as a default for now
          defaultCity: cities.data.data.citiesList[0],
        })
      );
    } else {
      throw new Error();
    }
  } catch (e) {
    // console.log(e);
    dispatch(setAllCitiesError('Failed to fetch the cities list'));
  } finally {
    dispatch(setAllCitiesLoading(false));
  }
};
