import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {Box} from '@material-ui/core';
import FeaturedRestaurantsListContainer from './FeaturedRestaurantsListContainer';
import OverlayError from '../Error/OverlayError';
import {getFeaturedListsByCity} from '../../redux/thunks/getFeaturedListsByCity';

/**
 * A component that'll fetch & show the featured restaurants for a selected city
 */
function FeaturedRestaurantsSections({city}) {
  const dispatch = useDispatch();

  const featuredRestaurantsList = useSelector(state => {
    if (city?._id) return state.city.featuredLists.restaurantLists.byCity?.[city?._id];
    return null;
  });

  const isLoading = useSelector(state => state.city.featuredLists.loading);
  const error = useSelector(state => state.city.featuredLists.error);
  const skeleton = !!(isLoading || error || !city || !featuredRestaurantsList);

  const retryHandler = () => {
    dispatch(getFeaturedListsByCity({city}));
  };

  return (
    <OverlayError
      error={Boolean(error)}
      message="Failed to fetch the featured restaurants."
      onRetry={retryHandler}
    >
      {skeleton && (
        <section>
          <FeaturedRestaurantsListContainer skeleton={skeleton} />
        </section>
      )}
      {!skeleton &&
        featuredRestaurantsList.map(({listName, discription, restaurants}) => (
          <Box key={listName} component="section" my={5}>
            <FeaturedRestaurantsListContainer
              title={listName}
              skeleton={skeleton}
              description={discription}
              restaurants={restaurants}
            />
          </Box>
        ))}
    </OverlayError>
  );
}

FeaturedRestaurantsSections.propTypes = {
  city: PropTypes.shape({_id: PropTypes.string.isRequired, cityName: PropTypes.string.isRequired}),
};

FeaturedRestaurantsSections.defaultProps = {
  city: null,
};

export default FeaturedRestaurantsSections;
