import React from 'react';
import PropTypes from 'prop-types';
import {Box, Container, Divider} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {fetchBlogById} from '../../redux/slices/blog';
import BlogHeadingSection from './BlogHeadingSection';
import BlogContentSection from './BlogContentSection';

/**
 * This component will fetch the blog post by its id and display it.
 */
function BlogContainer({id}) {
  const dispatch = useDispatch();

  const blog = useSelector(state => state.blog.byId?.[id]);

  const isLoading = useSelector(state => state.blog.loading);
  const error = useSelector(state => state.blog.error);
  const skeleton = isLoading || error || !blog;

  React.useEffect(() => {
    dispatch(fetchBlogById(id));
  }, [id, dispatch]);

  return (
    <Container>
      {skeleton ? (
        <BlogHeadingSection skeleton={skeleton} />
      ) : (
        <BlogHeadingSection
          id={blog.slug.current}
          author={blog.author}
          category={blog.category}
          city={blog.city}
          featureImage={blog.mainImage.asset}
          tags={blog.tags}
          title={blog.title}
          // eslint-disable-next-line no-underscore-dangle
          publishedAt={blog._createdAt}
        />
      )}
      <Box my={5}>
        <Divider light />
      </Box>
      <Box my={5}>
        <BlogContentSection body={blog?.body} skeleton={skeleton} />
      </Box>
    </Container>
  );
}

BlogContainer.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default BlogContainer;
