import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';
import Navbar from '../Navbar';
import Footer from '../Footer';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100vh',
  },
  content: {
    marginTop: '100px',
  },
  [theme.breakpoints.down('md')]: {
    content: {
      marginTop: '90px',
    },
  },
  [theme.breakpoints.down('xs')]: {
    content: {
      marginTop: '80px',
    },
  },
}));

/**
 * this component will add the navbar & footer to a children component
 */
function Layout({children}) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Navbar />
      <div className={classes.content}>{children}</div>
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
