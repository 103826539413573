import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import {makeStyles, Typography, Link as MuiLink, Divider} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import clsx from 'clsx';
import {Link as RouterLink} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  featuredImageContainer: {
    position: 'relative',
    flexBasis: '30%',
    minWidth: theme.typography.pxToRem(150),
    maxWidth: theme.typography.pxToRem(250),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.typography.pxToRem(theme.spacing(2)),
    },
  },
  imageBox: {
    position: 'relative',
    height: 0,
    width: '100%',
    paddingTop: '56.25%',
    '& > *:first-child': {
      display: 'block',
      height: '100%',
      margin: 0,
      padding: 0,
      position: 'absolute',
      top: 0,
      width: '100%',
    },
  },
  content: {
    paddingLeft: theme.spacing(2),
    flexBasis: '70%',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  marginRightAllChild: {
    display: 'flex',
    alignItems: 'baseline',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  disabledLink: {
    outline: 'none',
    textDecoration: 'none',
    pointerEvents: 'none',
    '&:hover': {
      outline: 'none',
      textDecoration: 'none',
    },
  },
}));

/**
 * A compact/small card of the blog post for the archives pages (i.e. by author, by tags, by category etc.)
 */
function ArchiveBlogCard({id, title, featureImage, author, publishedAt, skeleton}) {
  const classes = useStyles({skeleton});
  return (
    <div className={classes.container}>
      <MuiLink
        tabIndex="-1"
        aria-label={title}
        aria-hidden="true"
        to={skeleton ? `#!` : `/blog/${id}`}
        component={skeleton ? 'span' : RouterLink}
        className={clsx(classes.featuredImageContainer, {
          [classes.disabledLink]: skeleton,
        })}
      >
        <div className={classes.imageBox}>
          {skeleton && <Skeleton variant="rect" width="100%" height="100%" />}
          {!skeleton && <img src={featureImage?.url} alt={title} />}
        </div>
      </MuiLink>
      <div className={classes.content}>
        <MuiLink
          color="textPrimary"
          tabIndex={skeleton ? '-1' : undefined}
          className={clsx({
            [classes.disabledLink]: skeleton,
          })}
          component={skeleton ? 'span' : RouterLink}
          to={skeleton ? `#!` : `/blog/${id}`}
        >
          <Typography className={classes.title} component="h2" variant="body1">
            {skeleton ? (
              <>
                <Skeleton width="90%" />
                <Skeleton width="60%" />
              </>
            ) : (
              title
            )}
          </Typography>
        </MuiLink>
        <Typography
          color="textSecondary"
          className={classes.marginRightAllChild}
          component="div"
          variant="subtitle2"
        >
          {skeleton ? (
            <Skeleton width={70} />
          ) : (
            <Typography variant="inherit">
              By{' '}
              <MuiLink component={RouterLink} to={`/blog/author/${author.slug.current}`}>
                {`${author.name}`}
              </MuiLink>
            </Typography>
          )}
          <Divider orientation="vertical" flexItem />
          <Typography variant="inherit">
            {skeleton ? (
              <Skeleton width={90} />
            ) : (
              <Moment date={publishedAt} format="MMMM D, YYYY" interval={0} withTitle />
            )}
          </Typography>
        </Typography>
      </div>
    </div>
  );
}

ArchiveBlogCard.propTypes = {
  /**
   * id is required to create the URL for the blog post
   */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * the title of the blog post
   */
  title: PropTypes.string,
  /**
   * a featured image for the blog post
   */
  featureImage: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  /**
   * username, first name, and last name of the blog author
   */
  author: PropTypes.shape({
    name: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
    slug: PropTypes.shape({
      current: PropTypes.string.isRequired,
    }).isRequired,
  }),
  /**
   * published date of the blogpost
   */
  publishedAt: PropTypes.string,
  /**
   * Set it to true if you want to show the loading indicator.
   * If true, then you don't have to give values for the rest of the props in this component.
   */
  skeleton: PropTypes.bool,
};

ArchiveBlogCard.defaultProps = {
  id: null,
  title: null,
  featureImage: null,
  author: null,
  publishedAt: null,
  skeleton: false,
};

export default ArchiveBlogCard;
