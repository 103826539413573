import React from 'react';
import PropTypes from 'prop-types';
import {Button, makeStyles} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import TagsModal from '../Modal/TagsModal';

const useStyles = makeStyles(theme => ({
  icons: {
    display: 'flex',
    '& > * + *': {
      display: 'block',
      marginLeft: theme.spacing(1),
    },
  },
  icon: {
    display: 'block',
    width: '1.2rem',
    height: '1.2rem',
    objectFit: 'container',
  },
}));

function TagsButton({tags, tagCategories, featureTags, skeleton}) {
  const classes = useStyles();

  const [isTagsModalOpen, setIsTagsModalOpen] = React.useState(false);
  return (
    <>
      {!skeleton && (
        <TagsModal
          open={isTagsModalOpen}
          onClose={() => {
            setIsTagsModalOpen(open => !open);
          }}
          tags={tags}
          tagCategories={tagCategories}
        />
      )}
      <Button
        onClick={() => {
          setIsTagsModalOpen(true);
        }}
        disabled={skeleton}
      >
        <div className={classes.icons}>
          {!skeleton &&
            !!featureTags?.length &&
            featureTags.map(({_id, title, icon}) => (
              <span key={_id}>
                <img
                  className={classes.icon}
                  src={`https://projectres.blob.core.windows.net/icons/${icon}`}
                  alt={title}
                />
              </span>
            ))}
        </div>
        {skeleton && <Skeleton variant="rect" width={50} />}
      </Button>
    </>
  );
}

TagsButton.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      title: PropTypes.string,
      categoryID: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
  featureTags: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      title: PropTypes.string,
      categoryID: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
  tagCategories: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  skeleton: PropTypes.bool,
};

TagsButton.defaultProps = {
  tags: null,
  tagCategories: null,
  featureTags: null,
  skeleton: false,
};

export default TagsButton;
