import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  item: {
    width: '50px !important',
    height: '50px !important',
    border: '1px solid',
    background: 'none',
    padding: 0,
    cursor: 'pointer',
  },
  img: {
    width: '100%',
    height: '100%',
  },
}));

function ModalGalleryThumbnailSliderItem({onClick: onSlideClick, index, thumbnailUrl, ...props}) {
  const classes = useStyles();
  const onClick = React.useCallback(() => {
    onSlideClick(index);
  }, [index, onSlideClick]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <button {...props} type="button" onClick={onClick} className={classes.item}>
      <img className={classes.img} src={thumbnailUrl} alt={index} />
    </button>
  );
}

ModalGalleryThumbnailSliderItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  thumbnailUrl: PropTypes.string.isRequired,
};

export default ModalGalleryThumbnailSliderItem;
