import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import {ClickAwayListener, Container, fade, IconButton, makeStyles} from '@material-ui/core';
import ModalGallerySlider from './ModalGallerySlider/ModalGallerySlider';
import ModalGalleryThumbnailSlider from './ModalGalleryThumbnailSlider/ModalGalleryThumbnailSlider';

const useStyles = makeStyles(theme => ({
  slider: {},
  overlay: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: theme.zIndex.modal,
    background: fade(theme.palette.primary.dark, 0.95),
  },
  closeBtn: {
    position: 'absolute',
    top: '1.5rem',
    right: '1rem',
    color: theme.palette.common.white,
    zIndex: theme.zIndex.modal,
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.04),
    },
  },
  container: {
    padding: 0,
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    marginTop: '2rem',
  },
  thumbnailSliderContainer: {
    maxWidth: '350px',
    margin: '1rem auto',
    padding: '0 2rem',
  },
}));

function ModalGalleryViewer({open, onClose, slides, currentSlide}) {
  const classes = useStyles();

  const modalGallerySliderRef = React.useRef(null);

  const onThumbnailSlideClick = React.useCallback(slide => {
    modalGallerySliderRef.current.goToSlide(slide);
  }, []);

  return (
    open && (
      <div className={classes.overlay}>
        <IconButton size="small" className={classes.closeBtn} aria-label="close">
          <CloseIcon fontSize="large" />
        </IconButton>
        <ClickAwayListener onClickAway={onClose}>
          <Container maxWidth="lg" className={classes.container}>
            <ModalGallerySlider
              ref={modalGallerySliderRef}
              slides={slides}
              currentSlide={currentSlide}
            />
            <div className={classes.thumbnailSliderContainer}>
              <ModalGalleryThumbnailSlider
                slides={slides}
                currentSlide={currentSlide}
                onSlideClick={onThumbnailSlideClick}
              />
            </div>
          </Container>
        </ClickAwayListener>
      </div>
    )
  );
}

ModalGalleryViewer.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      thumbnailUrl: PropTypes.string.isRequired,
    })
  ).isRequired,
  currentSlide: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ModalGalleryViewer;
