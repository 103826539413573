import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getBlogArchives} from '../../redux/thunks/getBlogArchives';
import BlogList from '../List/BlogList';

/**
 * This component will fetch/show the blog posts by the selected year and month
 */
function BlogArchiveList() {
  const dispatch = useDispatch();
  const curFilter = useSelector(state => state.blogArchive.curFilter);
  const blogIds = useSelector(state => state.blogArchive.byYM[curFilter.YM]?.data);
  const blogs = useSelector(state => {
    if (blogIds?.length) {
      return blogIds.map(id => state.blog.byId[id]);
    }
    return null;
  });
  const isLoading = useSelector(state =>
    typeof state.blogArchive.byYM[curFilter.YM]?.loading === 'boolean'
      ? state.blogArchive.byYM[curFilter.YM]?.loading
      : true
  );
  const error = useSelector(state => state.blogArchive.byYM[curFilter.YM]?.error);
  const hasNext = useSelector(state => state.blogArchive.byYM[curFilter.YM]?.hasNext);

  const skeleton = !!(isLoading || error);
  const noData = !blogs && !isLoading && !hasNext;
  const isLoadMoreDisabled = !!(!hasNext || error || isLoading);

  React.useEffect(() => {
    if (hasNext) {
      dispatch(getBlogArchives(curFilter));
    }
  }, [curFilter, hasNext, dispatch]);

  return (
    <BlogList
      blogs={blogs}
      noData={noData}
      skeleton={skeleton}
      buttonProps={{
        onClick: () => {
          dispatch(getBlogArchives(curFilter));
        },
        disabled: isLoadMoreDisabled,
      }}
    />
  );
}

export default BlogArchiveList;
