import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Link as MuiLink,
  Button,
  List,
  ListItem,
  makeStyles,
  fade,
} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';

import clsx from 'clsx';
import Fade from '../Transitions/Fade';

const useStyles = makeStyles(theme => ({
  tabActive: {
    'background-color': theme.palette.action.hover,
    color: theme.palette.common.black,
  },
  leftColumn: {
    '& > *': {
      marginRight: '1rem !important',
      marginLeft: '-1rem !important',
    },
    '& button': {
      justifyContent: 'flex-end',
      textAlign: 'right',
    },
    '& ul li': {
      justifyContent: 'flex-end',
      textAlign: 'right',
    },
  },
  rightColumn: {
    '& button': {
      justifyContent: 'flex-start',
      textAlign: 'left',
    },
  },
  column: {
    '& ul li': {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  },
  label: {
    color: fade(theme.palette.text.primary, 0.8),
  },
  labelActive: {
    color: theme.palette.common.black,
  },
  restaurantLabel: {
    textTransform: 'capitalize',
  },
}));

function CuisineAccordion({cuisine, selected, side, onClick, restaurants}) {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.column, {
        [classes.leftColumn]: side === 'left',
        [classes.rightColumn]: side === 'right',
      })}
    >
      <Button
        className={clsx({
          [classes.label]: !selected,
          [classes.tabActive]: selected,
        })}
        onClick={onClick}
        fullWidth
      >
        {cuisine}
      </Button>
      {selected && (
        <Fade>
          <List dense>
            {restaurants.map(({_id, name}) => (
              <ListItem divider key={_id}>
                <MuiLink color="textPrimary" component={RouterLink} to="#!">
                  <Typography
                    className={clsx(classes.labelActive, classes.restaurantLabel)}
                    component="h2"
                    variant="subtitle2"
                  >
                    {name}
                  </Typography>
                </MuiLink>
              </ListItem>
            ))}
          </List>
        </Fade>
      )}
    </div>
  );
}

CuisineAccordion.propTypes = {
  cuisine: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  side: PropTypes.oneOf(['left', 'right']).isRequired,
  onClick: PropTypes.func.isRequired,
  restaurants: PropTypes.arrayOf(
    PropTypes.shape({name: PropTypes.string.isRequired, _id: PropTypes.string.isRequired})
  ).isRequired,
};

export default CuisineAccordion;
