import React from 'react';
import {useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {useDispatch} from 'react-redux';
import {Container} from '@material-ui/core';
import Layout from '../components/Layout';
import {prepareInitialAuthorBlogsState} from '../redux/slices/author-blog';
import AuthorBlogsContainer from '../components/AuthorBlogs/AuthorBlogsContainer';

/**
 * A page that'll show the author's blogs
 */
function AuthorBlogPosts() {
  const {author} = useParams();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(prepareInitialAuthorBlogsState(author));
  }, [author, dispatch]);

  return (
    <Layout>
      <Helmet>
        <title>Blogs | Khaaba</title>
      </Helmet>
      <Container maxWidth="md">
        <AuthorBlogsContainer authorId={author} />
      </Container>
    </Layout>
  );
}

export default AuthorBlogPosts;
