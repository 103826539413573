import {createSlice} from '@reduxjs/toolkit';

// CONSTANTS
export const SLOTS_MODAL_TYPE = 'slots-modal';
export const RESERVATION_COMPLETE_MODAL_TYPE = 'reservation-complete-modal';
// export const DEFAULT_RESERVATION_PARTY_SIZE_AND_DATE_RANGE = {};

const reservationSlice = createSlice({
  name: 'reservation',
  initialState: {
    modal: {
      type: SLOTS_MODAL_TYPE,
      open: false,
    },
    partySize: {
      min: 0,
      max: 0,
      current: 0,
    },
    reservationDate: {
      min: undefined,
      max: undefined,
      current: new Date(),
    },
    slots: [],
    currentSlot: null,
  },
  reducers: {
    setReservationModalType(state, action) {
      state.modal.type = action.payload;
    },
    setReservationModalOpen(state, action) {
      state.modal.open = action.payload;
      state.modal.type = SLOTS_MODAL_TYPE;
    },
    setReservationPartySizeAndDateRange(state, action) {
      const {minPartySize, maxPartySize, minReservationDate, maxReservationDate} = action.payload;
      state.partySize = {
        min: minPartySize,
        max: maxPartySize,
        current: minPartySize,
      };
      state.reservationDate = {
        min: minReservationDate,
        max: maxReservationDate,
        current: minReservationDate,
      };
    },
    setReservationPartySizeCurrent(state, action) {
      state.partySize.current = action.payload;
    },
    setReservationDateCurrent(state, action) {
      state.reservationDate.current = action.payload;
    },
    setReservationSlots(state, action) {
      state.slots = action.payload;
    },
    setReservationCurrentSlot(state, action) {
      state.currentSlot = action.payload;
    },
  },
});

export const {
  setReservationDateCurrent,
  setReservationPartySizeCurrent,
  setReservationPartySizeAndDateRange,
  setReservationModalType,
  setReservationModalOpen,
  setReservationCurrentSlot,
  setReservationSlots,
} = reservationSlice.actions;

export const reservationModalOpenSelect = state => state.reservation.modal.open;
export const reservationModalTypeSelect = state => state.reservation.modal.type;
export const reservationDateSelect = state => state.reservation.reservationDate;
export const reservationPartySizeSelect = state => state.reservation.partySize;
export const reservationDateCurrentSelect = state => state.reservation.reservationDate.current;
export const reservationPartySizeCurrentSelect = state => state.reservation.partySize.current;
export const reservationSlotsSelect = state => state.reservation.slots;
export const reservationCurrentSlotSelect = state => state.reservation.currentSlot;

export default reservationSlice.reducer;
