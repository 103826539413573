import React from 'react';
import PropTypes from 'prop-types';
import Rating from '@material-ui/lab/Rating';
import {Typography} from '@material-ui/core';

function Review({IconComponent, review, totalReviews}) {
  return (
    <div style={{display: 'flex'}}>
      <IconComponent style={{width: '2rem', height: '2rem', marginRight: '1rem'}} />
      <div>
        <Rating value={review} readOnly />
        <Typography variant="subtitle2">{totalReviews.toLocaleString()}</Typography>
      </div>
    </div>
  );
}

Review.propTypes = {
  IconComponent: PropTypes.element.isRequired,
  review: PropTypes.number.isRequired,
  totalReviews: PropTypes.number.isRequired,
};

export default Review;
