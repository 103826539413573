import moment from 'moment';

/**
 * get all years till the current year & starting from the startYear
 * @param {number} startYear
 * @returns string[]
 */
export const getYears = (startYear = 2019) => {
  const years = [];
  const dateStart = moment.utc(`${startYear}`, 'YYYY', true);
  const dateEnd = moment.utc();

  while (dateEnd.diff(dateStart, 'years') > 0) {
    years.push(dateEnd.format('YYYY'));
    dateEnd.subtract(1, 'year');
  }
  years.push(dateStart.format('YYYY'));
  return years;
};

/**
 * get all months (current or passed) in a given year
 * @param {number} year
 * @returns string[]
 */
export const getAllMonths = year => {
  const now = moment.utc();
  if (year === now.get('year')) {
    return moment.months().slice(0, now.get('month') + 1);
  }
  return moment.months();
};
