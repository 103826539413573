import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Typography} from '@material-ui/core';
import {getCityBlogPosts} from '../../redux/thunks/getCityBlogPosts';
import BlogList from '../List/BlogList';

/**
 * This component fetches & shows (with pagination) the blogposts by a specific city
 */
function CityBlogsContainer({city}) {
  const dispatch = useDispatch();
  const blogIds = useSelector(state => state.cityBlog[city]?.data);
  const blogs = useSelector(state => {
    if (blogIds?.length) {
      return blogIds.map(id => state.blog.byId[id]);
    }
    return null;
  });
  const isLoading = useSelector(state =>
    typeof state.cityBlog[city]?.loading === 'boolean' ? state.cityBlog[city]?.loading : true
  );
  const error = useSelector(state => state.cityBlog[city]?.error);
  const hasNext = useSelector(state => state.cityBlog[city]?.hasNext);

  const skeleton = !!(isLoading || error);
  const noData = !blogs?.length && !isLoading && !hasNext;
  const isLoadMoreDisabled = !!(!hasNext || error || isLoading);

  React.useEffect(() => {
    if (hasNext) {
      dispatch(getCityBlogPosts({city}));
    }
  }, [city, hasNext, dispatch]);

  return (
    <div>
      <Box my={4}>
        <Typography component="h1" variant="h4">
          {blogs?.[0]?.city?.name ?? city}
        </Typography>
      </Box>
      <BlogList
        blogs={blogs}
        noData={noData}
        skeleton={skeleton}
        buttonProps={{
          onClick: () => {
            dispatch(getCityBlogPosts({city}));
          },
          disabled: isLoadMoreDisabled,
        }}
      />
    </div>
  );
}

CityBlogsContainer.propTypes = {
  /**
   * city associated with the blog posts
   */
  city: PropTypes.string.isRequired,
};

export default CityBlogsContainer;
