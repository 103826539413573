import React from 'react';
import {useSelector} from 'react-redux';
import CuisineColumns from './CuisineColumns';

function CuisineColumnsContainer() {
  const city = useSelector(state => state.city.currentCity);

  const loading = useSelector(state => state.city.featuredLists.loading);
  const error = useSelector(state => state.city.featuredLists.error);
  const cuisines = useSelector(state => {
    if (city?._id) return state.city.featuredLists.cuisinesList.byCity?.[city._id];
    return null;
  });

  const skeleton = Boolean(loading || error || !cuisines);

  return <CuisineColumns skeleton={skeleton} cuisines={cuisines} />;
}

export default CuisineColumnsContainer;
