import {createSlice} from '@reduxjs/toolkit';

const INITIAL_AUTHOR_BLOGS_DATA = {
  data: [],
  error: '',
  loading: true,
  curPage: 0,
  hasNext: true,
};

const authorBlogSlice = createSlice({
  name: 'author-blog',
  initialState: {
    byAuthor: {},
  },
  reducers: {
    prepareInitialAuthorBlogsState(state, action) {
      if (!state.byAuthor[action.payload]) {
        state.byAuthor[action.payload] = INITIAL_AUTHOR_BLOGS_DATA;
      }
    },
    appendBlogsByAuthor: {
      reducer(state, action) {
        state.byAuthor[action.payload.authorId].data.push(...action.payload.blogs);
      },
      prepare({authorId, blogs}) {
        const blogIds = blogs.map(blog => blog.slug.current);
        return {payload: {authorId, blogs: blogIds}};
      },
    },
    setAutorBlogsCurPage(state, action) {
      state.byAuthor[action.payload.authorId].curPage = action.payload.curPage;
    },
    setAuthorBlogsHasNext(state, action) {
      state.byAuthor[action.payload.authorId].hasNext = action.payload.hasNext;
    },
    setAuthorBlogsLoading(state, action) {
      state.byAuthor[action.payload.authorId].loading = action.payload.loading;
    },
    setAuthorBlogsError(state, action) {
      state.byAuthor[action.payload.authorId].error = action.payload.error;
    },
  },
});

export const {
  prepareInitialAuthorBlogsState,
  appendBlogsByAuthor,
  setAutorBlogsCurPage,
  setAuthorBlogsHasNext,
  setAuthorBlogsLoading,
  setAuthorBlogsError,
} = authorBlogSlice.actions;

export default authorBlogSlice.reducer;
