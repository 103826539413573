import React from 'react';
import PropTypes from 'prop-types';
import {GoogleMap, useLoadScript} from '@react-google-maps/api';
import {Skeleton} from '@material-ui/lab';
import {makeStyles, Typography} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  map: {
    width: '100%',
    minHeight: '400px',
  },
}));

function Map({coordinates, skeleton}) {
  const classes = useStyles();
  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: 'AIzaSyCivxaKFhSIJgYWcFHa_iY0k5f1yPvblcc', // ,
    // ...otherOptions
  });

  const onLoad = React.useCallback(() => {}, []);

  return (
    <div>
      {!skeleton && isLoaded && !loadError ? (
        <GoogleMap
          center={{
            lat: parseFloat(coordinates[0]),
            lng: parseFloat(coordinates[1]),
          }}
          mapContainerClassName={classes.map}
          zoom={15}
          onLoad={onLoad}
        />
      ) : (
        <Skeleton variant="rect" height="100%" width="100%" />
      )}
      {Boolean(loadError) && (
        <Typography color="error">Map cannot be loaded right now, sorry.</Typography>
      )}
    </div>
  );
}

Map.propTypes = {
  coordinates: PropTypes.arrayOf(PropTypes.string),
  skeleton: PropTypes.bool,
};

Map.defaultProps = {
  coordinates: null,
  skeleton: false,
};

export default Map;
