import {createSlice} from '@reduxjs/toolkit';

const citySlice = createSlice({
  name: 'city',
  initialState: {
    currentCity: null,
    loading: true,
    error: '',
    cities: null,
    featuredLists: {
      loading: true,
      error: '',
      restaurantLists: {
        byCity: {},
      },
      landMarks: {
        byCity: {},
      },
      neighbourhood: {
        byCity: {},
      },
      cuisinesList: {
        byCity: {},
      },
    },
  },
  reducers: {
    setCurrentCity(state, action) {
      state.currentCity = action.payload;
    },
    setAllCities(state, action) {
      state.cities = action.payload.cities;
      state.currentCity = action.payload.defaultCity;
    },
    setAllCitiesLoading(state, action) {
      state.loading = action.payload;
    },
    setAllCitiesError(state, action) {
      state.error = action.payload;
    },
    setFeaturedLists(state, action) {
      state.featuredLists.restaurantLists.byCity[action.payload._id] =
        action.payload.featuredRestaurantsList;
      state.featuredLists.landMarks.byCity[action.payload._id] = action.payload.landMarks;
      state.featuredLists.neighbourhood.byCity[action.payload._id] = action.payload.neighbourhood;
      state.featuredLists.cuisinesList.byCity[action.payload._id] = action.payload.cuisinesList;
    },
    setFeaturedListsLoading(state, action) {
      state.featuredLists.loading = action.payload;
    },
    setFeaturedListsError(state, action) {
      state.featuredLists.error = action.payload;
    },
  },
});

export const {
  setCurrentCity,
  setAllCities,
  setAllCitiesError,
  setAllCitiesLoading,
  setFeaturedLists,
  setFeaturedListsLoading,
  setFeaturedListsError,
} = citySlice.actions;

export default citySlice.reducer;
