import React from 'react';
import {Container, makeStyles, Link as MuiLink, Typography} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

function Footer() {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Container>
        <Typography variant="subtitle1">
          © {new Date().getFullYear()}{' '}
          <MuiLink color="textPrimary" component={RouterLink} to="/">
            Khaaba
          </MuiLink>{' '}
          All Rights Reserved
        </Typography>
      </Container>
    </footer>
  );
}

export default Footer;
