import {getBlogsByAuthor} from '../../api/blog';
import {PAGE_SIZE} from '../../config/env';
import {addBlogsById} from '../slices/blog';
import {
  appendBlogsByAuthor,
  setAuthorBlogsHasNext,
  setAutorBlogsCurPage,
  setAuthorBlogsLoading,
  setAuthorBlogsError,
} from '../slices/author-blog';

export const getAuthorBlogPosts =
  ({authorId}) =>
  async (dispatch, getState) => {
    try {
      await dispatch(setAuthorBlogsLoading({authorId, loading: true}));
      await dispatch(setAuthorBlogsError({authorId, error: ''}));
      const {authorBlog} = getState();
      const {curPage} = authorBlog.byAuthor[authorId];
      const blogs = await getBlogsByAuthor({
        authorId,
        _start: curPage * PAGE_SIZE,
        _limit: PAGE_SIZE,
      });

      if (blogs?.length) {
        await dispatch(addBlogsById(blogs));
        await dispatch(appendBlogsByAuthor({authorId, blogs}));
        await dispatch(setAutorBlogsCurPage({authorId, curPage: curPage + 1}));
      }
      if (blogs?.length !== PAGE_SIZE) {
        await dispatch(setAuthorBlogsHasNext({authorId, hasNext: false}));
      }
    } catch (e) {
      // console.log(e);
      dispatch(
        setAuthorBlogsError({
          authorId,
          error: 'Failed to fetch the author blog posts',
        })
      );
    } finally {
      dispatch(setAuthorBlogsLoading({authorId, loading: false}));
    }
  };
