import React from 'react';
import {OutlinedInput, InputAdornment, ButtonGroup, IconButton} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {useDispatch, useSelector} from 'react-redux';
import {reservationPartySizeSelect, setReservationPartySizeCurrent} from 'redux/slices/reservation';
import {useRestaurantDetails} from '../hooks/useRestaurantDetails';

function PartySizeInput() {
  const dispatch = useDispatch();
  const {skeleton: disabled} = useRestaurantDetails();
  const {min, max, current: value} = useSelector(reservationPartySizeSelect);

  const onAdditionValue = () => {
    if (value < max) {
      dispatch(setReservationPartySizeCurrent(value + 1));
    }
  };

  const onSubtractValue = () => {
    if (value > min) {
      dispatch(setReservationPartySizeCurrent(value - 1));
    }
  };

  const isAddDisabled = value >= max;
  const isSubDisabled = value <= min;

  return (
    <OutlinedInput
      value={`${value} Guests`}
      readOnly
      fullWidth
      endAdornment={
        <InputAdornment position="end">
          <ButtonGroup>
            <IconButton onClick={onSubtractValue} disabled={isSubDisabled || disabled}>
              <RemoveIcon />
            </IconButton>
            <IconButton onClick={onAdditionValue} disabled={isAddDisabled || disabled}>
              <AddIcon />
            </IconButton>
          </ButtonGroup>
        </InputAdornment>
      }
    />
  );
}

export default PartySizeInput;
