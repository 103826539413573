import React from 'react';
import {Calendar} from '@material-ui/pickers';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {reservationDateSelect, setReservationDateCurrent} from 'redux/slices/reservation';

function ReservationCalendarInput() {
  const dispatch = useDispatch();
  const {min: minDate, max: maxDate, current: value} = useSelector(reservationDateSelect);
  const values = React.useMemo(
    () => ({
      value: moment(value),
      minDate: moment(minDate),
      maxDate: moment(maxDate),
    }),
    [maxDate, minDate, value]
  );

  const onChange = React.useCallback(
    newDate => {
      dispatch(setReservationDateCurrent(newDate.utc().format()));
    },
    [dispatch]
  );

  return (
    <Calendar
      date={values.value}
      onChange={onChange}
      minDate={values.minDate}
      maxDate={values.maxDate}
      fullWidth
    />
  );
}

export default ReservationCalendarInput;
