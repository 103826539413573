import React from 'react';
import PropTypes from 'prop-types';
import {CircularProgress, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  item: {
    height: 'calc(100vh - 150px)',
    width: `${theme.breakpoints.values.md}px !important`,
    display: 'flex !important',
    alignItems: 'center',
    justifyContent: 'center',
  },
  img: {objectFit: 'contain', height: '100%', width: '100%'},
  progress: {
    color: theme.palette.common.white,
  },
}));

function ModalGallerySliderItem({index, url, ...props}) {
  const classes = useStyles();
  const [isImageLoaded, setIsImageLoaded] = React.useState(false);
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...props} className={classes.item}>
      <img
        onLoad={() => {
          setIsImageLoaded(true);
        }}
        className={classes.img}
        src={url}
        alt={index}
        style={{display: !isImageLoaded ? 'none' : undefined}}
      />
      {!isImageLoaded && <CircularProgress className={classes.progress} />}
    </div>
  );
}

ModalGallerySliderItem.propTypes = {
  index: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default ModalGallerySliderItem;
