import React from 'react';
import PropTypes from 'prop-types';
import {useAuth0} from '@auth0/auth0-react';
import ResponsiveButton from '../ResponsiveButton';

/**
 * this button will open the login modal after clicking it
 */
function LoginButton({fullWidth, size, variant, color, children}) {
  const {loginWithPopup} = useAuth0();
  return (
    <ResponsiveButton
      fullWidth={fullWidth}
      size={size}
      variant={variant}
      color={color}
      onClick={() => loginWithPopup()}
    >
      {children}
    </ResponsiveButton>
  );
}

/**
 * @see https://material-ui.com/api/button/
 */
LoginButton.propTypes = {
  fullWidth: PropTypes.bool,
  size: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
};

LoginButton.defaultProps = {
  fullWidth: false,
  size: 'medium',
  variant: 'text',
  color: 'default',
};

export default LoginButton;
