import {makeStyles, Link as MuiLink, Typography, fade} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
    overflow: 'hidden',
  },
  link: {
    outline: 'none',
    textDecoration: 'none',
    display: 'block',
    width: '100%',
    height: '100%',
    '&:hover': {
      outline: 'none',
      textDecoration: 'none',
    },
    backgroundColor: theme.palette.grey[200],
  },
  img: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    aspectRatio: '3/4',
  },
  imgSkeleton: {width: '100%', height: '100%', aspectRatio: '3/4'},
  textSkeleton: {backgroundColor: fade(theme.palette.common.white, 0.5)},
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.action.active,
    color: theme.palette.common.white,
    padding: theme.typography.pxToRem(theme.spacing(2)),
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.4,
    minHeight: '2.8em',
    display: 'flex',
    alignItems: 'center',
  },
}));

function LandmarkCard({skeleton, image, name, id}) {
  const classes = useStyles();

  return (
    <div className={classes.card}>
      {!skeleton && (
        <MuiLink
          tabIndex="-1"
          aria-label={name}
          aria-hidden="true"
          to={`/landmark/${id}`}
          component={RouterLink}
          className={classes.link}
        >
          <img className={classes.img} src={image} alt={name} />
        </MuiLink>
      )}
      {skeleton && <Skeleton variant="rect" className={classes.imgSkeleton} />}
      <div className={classes.footer}>
        {!skeleton && (
          <MuiLink color="inherit" aria-label={name} to={`/landmark/${id}`} component={RouterLink}>
            <Typography className={classes.title} variant="h6" component="h2">
              {name}
            </Typography>
          </MuiLink>
        )}
        {skeleton && (
          <>
            <Skeleton className={classes.textSkeleton} />
            <Skeleton width="30%" className={classes.textSkeleton} />
          </>
        )}
      </div>
    </div>
  );
}

LandmarkCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  image: PropTypes.string,
  skeleton: PropTypes.bool,
};

LandmarkCard.defaultProps = {
  id: null,
  name: null,
  image: null,
  skeleton: false,
};

export default LandmarkCard;
