import React from 'react';
import PropTypes from 'prop-types';
import {FaHeart as HeartIcon} from 'react-icons/fa';
import {IconButton, makeStyles} from '@material-ui/core';
import {useToggleRestaurantLikeMutation} from 'redux/query/server';

const useStyles = makeStyles(theme => ({
  likeButton: ({isLiked}) => ({
    color: isLiked ? theme.palette.error.main : theme.palette.action.disabled,
  }),
}));

function ToggleLikeIconButton({isLiked, restaurantId, skeleton}) {
  const classes = useStyles({isLiked});
  const [toggleLike, {isLoading}] = useToggleRestaurantLikeMutation();

  const toggleLikeHandler = React.useCallback(() => {
    if (!isLoading) {
      toggleLike({restaurantId});
    }
  }, [isLoading, restaurantId, toggleLike]);

  return (
    <IconButton
      disabled={skeleton}
      className={classes.likeButton}
      aria-label="add to favorite restaurant list"
      onClick={toggleLikeHandler}
    >
      <HeartIcon />
    </IconButton>
  );
}

ToggleLikeIconButton.propTypes = {
  isLiked: PropTypes.bool,
  skeleton: PropTypes.bool,
  restaurantId: PropTypes.string.isRequired,
};

ToggleLikeIconButton.defaultProps = {
  isLiked: false,
  skeleton: false,
};

export default ToggleLikeIconButton;
