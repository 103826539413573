import React from 'react';
import PropTypes from 'prop-types';
import {Button, makeStyles, Typography, Link} from '@material-ui/core';
import Moment from 'react-moment';
import {Skeleton} from '@material-ui/lab';
import {FaFacebookF, FaInstagram} from 'react-icons/fa';
import {BiRightArrowCircle} from 'react-icons/bi';
import TimingsModal from '../Modal/TimingsModal';
import FAQsModal from '../Modal/FAQsModal';
import Map from '../UI/Map';
import {useCurrentWeekTimings} from '../hooks/useCurrentWeekTimings';
import HourRange from '../UI/HourRange';

const useStyles = makeStyles(theme => ({
  outer: {
    // maxWidth: theme.breakpoints.values.sm,
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: theme.typography.fontWeightBold,
  },
  card: ({direction}) => ({
    display: 'flex',
    flexDirection: direction,
    flexGrow: 1,
    '& > *': {
      flexBasis: '50%',
      flexGrow: 1,
      flexShrink: 0,
    },
    '& > *:last-child': {
      paddingLeft: direction === 'row' ? '2rem' : 0,
      paddingTop: direction === 'column' ? '2rem' : 0,
    },
  }),
  map: {
    width: '100%',
    minHeight: theme.typography.pxToRem(400),
  },
  contentRow: {
    borderBottom: '1px solid #ede9e6',
    marginBottom: '1rem',
    paddingBottom: '1rem',
  },
  icons: {
    display: 'flex',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  icon: {
    fontSize: theme.typography.h2.fontSize,
    color: theme.palette.primary.main,
  },
}));

function RestaurantDetailsCard({
  coordinates,
  faqs,
  restaurantName,
  socialHandles,
  weeklySchedule,
  address,
  phoneNo,
  website,
  skeleton,
  direction,
}) {
  const classes = useStyles({direction});
  const [isFaqOpen, setFaqOpen] = React.useState(false);
  const [isTimingsOpen, setTimingsOpen] = React.useState(false);
  const curWeekdayTimings = useCurrentWeekTimings(weeklySchedule);

  return (
    <div className={classes.outer}>
      <TimingsModal
        open={isTimingsOpen}
        onClose={() => {
          setTimingsOpen(false);
        }}
        weeklySchedule={weeklySchedule}
      />
      <FAQsModal
        restaurantName={restaurantName}
        open={isFaqOpen}
        onClose={() => {
          setFaqOpen(false);
        }}
        faqs={faqs}
      />
      <div className={classes.card}>
        <Map coordinates={coordinates} skeleton={skeleton} />
        <div>
          <div className={classes.contentRow}>
            <Typography color="textPrimary">{skeleton ? <Skeleton /> : address}</Typography>
          </div>
          {website && (
            <div className={classes.contentRow}>
              <Link target="_blank" rel="noopener noreferrer" href={website}>
                {website}
              </Link>
            </div>
          )}
          <div className={classes.contentRow}>
            <Link href={`tel:+${phoneNo}`}>{skeleton ? <Skeleton /> : `+${phoneNo}`}</Link>
          </div>
          <div className={classes.contentRow}>
            <Button
              onClick={() => {
                setFaqOpen(true);
              }}
              className={classes.textButton}
              disabled={skeleton}
            >
              Frequently asked questions
            </Button>
          </div>
          <div className={classes.contentRow}>
            <Button
              onClick={() => {
                setTimingsOpen(true);
              }}
              disabled={skeleton}
              endIcon={<BiRightArrowCircle />}
            >
              {!skeleton && (
                <div style={{display: 'flex'}}>
                  <Typography>
                    <Moment tz="Asia/Karachi" format="dddd" />
                  </Typography>
                  <div style={{marginLeft: '1rem'}}>
                    {curWeekdayTimings.hours.map(timing => (
                      <HourRange range={timing} />
                    ))}
                    {!curWeekdayTimings?.isOpen && <Typography>Closed</Typography>}
                  </div>
                </div>
              )}
              {skeleton && <Skeleton width={150} />}
            </Button>
          </div>
          <div className={classes.icons}>
            {!skeleton &&
              socialHandles.map(({type, url}) => {
                if (type === 'facebook')
                  return (
                    <Link target="_blank" rel="noopener noreferrer" key={type + url} href={url}>
                      <FaFacebookF className={classes.icon} />
                    </Link>
                  );
                if (type === 'instagram')
                  return (
                    <Link target="_blank" rel="noopener noreferrer" key={type + url} href={url}>
                      <FaInstagram className={classes.icon} />
                    </Link>
                  );
                return null;
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

RestaurantDetailsCard.propTypes = {
  coordinates: PropTypes.arrayOf(PropTypes.string),
  skeleton: PropTypes.bool,
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
    })
  ),
  socialHandles: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  restaurantName: PropTypes.string,
  weeklySchedule: PropTypes.arrayOf(
    PropTypes.shape({
      isOpen: PropTypes.bool,
      _id: PropTypes.string,
      dayNo: PropTypes.number,
      hours: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          from: PropTypes.number,
          additionTo: PropTypes.number,
        })
      ),
    })
  ),
  address: PropTypes.string,
  website: PropTypes.string,
  phoneNo: PropTypes.number,
  direction: PropTypes.oneOf(['row', 'column']),
};

RestaurantDetailsCard.defaultProps = {
  coordinates: null,
  faqs: null,
  socialHandles: null,
  restaurantName: null,
  weeklySchedule: null,
  address: null,
  website: null,
  phoneNo: null,
  skeleton: false,
  direction: 'row',
};

export default RestaurantDetailsCard;
