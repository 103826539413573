import React from 'react';
import PropTypes from 'prop-types';
import {NavLink as RouterNavLink, useHistory} from 'react-router-dom';
import {
  List,
  ListItem,
  Link as MuiLink,
  Typography,
  makeStyles,
  Hidden,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import {getAllMonths, getYears} from '../../utils/moment';

const YEARS = getYears(2015);

const useStyles = makeStyles(theme => ({
  link: {
    transition: theme.transitions.create(['color', 'background'], {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.easeOut,
    }),
    display: 'block',
    width: '100%',
    padding: theme.spacing(1),
  },
  activeLink: {
    color: '#ffffff',
    background: theme.palette.primary.main,
    display: 'block',
  },
  selectContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(2),
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(3),
    },
    '& > :last-child': {
      flexBasis: '80%',
    },
    '& > :first-child': {
      flexBasis: '20%',
    },
  },
}));

/**
 * This component will show the years & months list for selection
 */
function YearMonthFilter({year, month}) {
  const history = useHistory();
  const classes = useStyles();

  const MONTHS = React.useMemo(() => getAllMonths(year), [year]);

  const handleYearChange = ({target}) => {
    history.push(`/blog/archive/${target.value}/1`);
  };

  const handleMonthChange = ({target}) => {
    history.push(`/blog/archive/${year}/${target.value}`);
  };

  return (
    <div>
      <Typography gutterBottom align="center" variant="h5">
        Filter
      </Typography>
      <Hidden xsDown>
        <Typography variant="button">Year</Typography>
        <List dense>
          {YEARS.map(_year => (
            <ListItem dense key={_year}>
              <MuiLink
                className={classes.link}
                isActive={() => year === parseInt(_year, 10)}
                activeClassName={classes.activeLink}
                underline="none"
                to={`/blog/archive/${_year}/1`}
                component={RouterNavLink}
              >
                {_year}
              </MuiLink>
            </ListItem>
          ))}
        </List>
        <Typography variant="button">Month</Typography>
        <List dense>
          {MONTHS.map((_month, i) => (
            <ListItem dense key={_month}>
              <MuiLink
                isActive={() => month === i + 1}
                className={classes.link}
                activeClassName={classes.activeLink}
                underline="none"
                to={`/blog/archive/${year}/${i + 1}`}
                component={RouterNavLink}
              >
                {_month}
              </MuiLink>
            </ListItem>
          ))}
        </List>
      </Hidden>
      <Hidden smUp>
        <div className={classes.selectContainer}>
          <InputLabel id="year-select-label">Year</InputLabel>
          <Select
            labelId="year-select-label"
            id="year-select"
            value={year}
            onChange={handleYearChange}
          >
            {YEARS.map(_year => (
              <MenuItem key={_year} value={_year}>
                {_year}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className={classes.selectContainer}>
          <InputLabel id="year-select-label">Month</InputLabel>
          <Select
            labelId="month-select-label"
            id="month-select"
            value={month}
            onChange={handleMonthChange}
          >
            {MONTHS.map((_month, i) => (
              <MenuItem key={_month} value={i + 1}>
                {_month}
              </MenuItem>
            ))}
          </Select>
        </div>
      </Hidden>
    </div>
  );
}

YearMonthFilter.propTypes = {
  /**
   * selected year for blogs
   */
  year: PropTypes.number.isRequired,
  /**
   * selected month for blogs
   */
  month: PropTypes.number.isRequired,
};

export default YearMonthFilter;
