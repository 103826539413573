import {createSlice} from '@reduxjs/toolkit';
import {getBlogById} from '../../api/blog';

const blogSlice = createSlice({
  name: 'blog',
  initialState: {
    byId: {},
    error: '',
    loading: true,
  },
  reducers: {
    addBlogsById(state, action) {
      action.payload.forEach(blog => {
        state.byId[blog.slug.current] = blog;
      });
    },
    addBlogById(state, action) {
      state.byId[action.payload.slug.current] = action.payload;
    },
    setBlogFetchError(state, action) {
      state.error = action.payload;
    },
    setBlogLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const {addBlogsById, addBlogById, setBlogFetchError, setBlogLoading} = blogSlice.actions;

export const fetchBlogById = id => async (dispatch, getState) => {
  try {
    dispatch(setBlogLoading(true));
    dispatch(setBlogFetchError(''));
    const state = getState();
    if (!state.blog.byId[id]) {
      const blog = await getBlogById(id);
      dispatch(addBlogById(blog));
    }
  } catch (error) {
    dispatch(setBlogFetchError('Failed to fetch the blog'));
  } finally {
    dispatch(setBlogLoading(false));
  }
};

export default blogSlice.reducer;
