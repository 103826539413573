import {createSlice} from '@reduxjs/toolkit';

const INITIAL_AUTHOR_BLOGS_DATA = {
  data: [],
  error: '',
  loading: true,
  curPage: 0,
  hasNext: true,
};

const blogArchiveSlice = createSlice({
  name: 'blog-archive',
  initialState: {
    byYM: {},
    curFilter: {
      YM: '',
      year: 0,
      month: 0,
      startDT: '',
      endDT: '',
    },
  },
  reducers: {
    prepareInitialBlogArchivesState(state, action) {
      state.curFilter.YM = action.payload.YM;
      state.curFilter.startDT = action.payload.startDT;
      state.curFilter.endDT = action.payload.endDT;
      state.curFilter.year = action.payload.year;
      state.curFilter.month = action.payload.month;
      if (!state.byYM[action.payload.YM]) {
        state.byYM[action.payload.YM] = INITIAL_AUTHOR_BLOGS_DATA;
      }
    },
    appendBlogsByYearMonth: {
      reducer(state, action) {
        state.byYM[action.payload.YM].data.push(...action.payload.blogs);
      },
      prepare({YM, blogs}) {
        const blogIds = blogs.map(blog => blog.slug.current);
        return {payload: {YM, blogs: blogIds}};
      },
    },
    setBlogArchivesCurPage(state, action) {
      state.byYM[action.payload.YM].curPage = action.payload.curPage;
    },
    setBlogArchivesHasNext(state, action) {
      state.byYM[action.payload.YM].hasNext = action.payload.hasNext;
    },
    setBlogArchivesLoading(state, action) {
      state.byYM[action.payload.YM].loading = action.payload.loading;
    },
    setBlogArchivesError(state, action) {
      state.byYM[action.payload.YM].error = action.payload.error;
    },
  },
});

export const {
  prepareInitialBlogArchivesState,
  appendBlogsByYearMonth,
  setBlogArchivesCurPage,
  setBlogArchivesHasNext,
  setBlogArchivesLoading,
  setBlogArchivesError,
} = blogArchiveSlice.actions;

export default blogArchiveSlice.reducer;
