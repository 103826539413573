import React from 'react';
import PropTypes from 'prop-types';
import RestaurantCard from 'components/Cards/RestaurantCard';
import RestaurantGrid from 'components/Home/RestaurantGrid';
import {Typography} from '@material-ui/core';

function RelatedRestaurants({restaurants, skeleton}) {
  return (
    <>
      <Typography style={{marginBottom: '1rem', fontWeight: 'bold'}} variant="h5">
        Related Restaurants
      </Typography>
      <RestaurantGrid>
        {!skeleton &&
          restaurants.map(({_id, images, cuisineCategory, neighborhood, name}) => (
            <RestaurantCard
              key={_id}
              images={images}
              cuisineCategory={cuisineCategory}
              name={name}
              neighborhood={neighborhood}
              id={_id}
            />
          ))}
        {skeleton && (
          <>
            <RestaurantCard skeleton />
            <RestaurantCard skeleton />
            <RestaurantCard skeleton />
            <RestaurantCard skeleton />
          </>
        )}
      </RestaurantGrid>
    </>
  );
}

RelatedRestaurants.propTypes = {
  restaurants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string.isRequired,
          thumbnailUrl: PropTypes.string.isRequired,
        })
      ),
      cuisineCategory: PropTypes.string,
      neighborhood: PropTypes.string,
      skeleton: PropTypes.bool,
    })
  ),
  skeleton: PropTypes.bool,
};

RelatedRestaurants.defaultProps = {
  restaurants: null,
  skeleton: false,
};

export default RelatedRestaurants;
