import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Typography} from '@material-ui/core';
import {getCategoryBlogPosts} from '../../redux/thunks/getCategoryBlogPosts';
import BlogList from '../List/BlogList';

/**
 * This component fetches & shows (with pagination) the blogposts by a specific category
 */
function CategoryBlogsContainer({category}) {
  const dispatch = useDispatch();
  const blogIds = useSelector(state => state.categoryBlog.byCategory[category]?.data);
  const blogs = useSelector(state => {
    if (blogIds?.length) {
      return blogIds.map(id => state.blog.byId[id]);
    }
    return null;
  });
  const isLoading = useSelector(state =>
    typeof state.categoryBlog.byCategory[category]?.loading === 'boolean'
      ? state.categoryBlog.byCategory[category]?.loading
      : true
  );
  const error = useSelector(state => state.categoryBlog.byCategory[category]?.error);
  const hasNext = useSelector(state => state.categoryBlog.byCategory[category]?.hasNext);

  const skeleton = !!(isLoading || error);
  const noData = !blogs?.length && !isLoading && !hasNext;
  const isLoadMoreDisabled = !!(!hasNext || error || isLoading);

  React.useEffect(() => {
    if (hasNext) {
      dispatch(getCategoryBlogPosts({category}));
    }
  }, [category, hasNext, dispatch]);

  return (
    <div>
      <Box my={4}>
        <Typography component="h1" variant="h4">
          {blogs?.[0]?.category?.title ?? category}
        </Typography>
      </Box>
      <BlogList
        blogs={blogs}
        noData={noData}
        skeleton={skeleton}
        buttonProps={{
          onClick: () => {
            dispatch(getCategoryBlogPosts({category}));
          },
          disabled: isLoadMoreDisabled,
        }}
      />
    </div>
  );
}

CategoryBlogsContainer.propTypes = {
  /**
   * category of blog posts
   */
  category: PropTypes.string.isRequired,
};

export default CategoryBlogsContainer;
