import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Typography} from '@material-ui/core';
import {getAuthorBlogPosts} from '../../redux/thunks/getAuthorBlogPosts';
import BlogList from '../List/BlogList';

/**
 * This component fetches & shows (with pagination) the blogposts by a specific author
 */
function AuthorBlogsContainer({authorId}) {
  const dispatch = useDispatch();
  const authorBlogIds = useSelector(state => state.authorBlog.byAuthor[authorId]?.data);
  const authorBlogs = useSelector(state => {
    if (authorBlogIds?.length) {
      return authorBlogIds.map(id => state.blog.byId[id]);
    }
    return null;
  });
  const isLoading = useSelector(state =>
    typeof state.authorBlog.byAuthor[authorId]?.loading === 'boolean'
      ? state.authorBlog.byAuthor[authorId]?.loading
      : true
  );
  const error = useSelector(state => state.authorBlog.byAuthor[authorId]?.error);
  const hasNext = useSelector(state => state.authorBlog.byAuthor[authorId]?.hasNext);

  const skeleton = !!(isLoading || error);
  const noData = !authorBlogs?.length && !isLoading && !hasNext;
  const isLoadMoreDisabled = !!(!hasNext || error || isLoading);

  React.useEffect(() => {
    if (hasNext) {
      dispatch(getAuthorBlogPosts({authorId}));
    }
  }, [authorId, hasNext, dispatch]);

  return (
    <div>
      <Box my={4}>
        <Typography component="h1" variant="h4">
          Blogs by {authorBlogs?.[0]?.author?.name ?? authorId}
        </Typography>
      </Box>
      <BlogList
        blogs={authorBlogs}
        noData={noData}
        skeleton={skeleton}
        buttonProps={{
          onClick: () => {
            dispatch(getAuthorBlogPosts({authorId}));
          },
          disabled: isLoadMoreDisabled,
        }}
      />
    </div>
  );
}

AuthorBlogsContainer.propTypes = {
  /**
   * author of the blog posts
   */
  authorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default AuthorBlogsContainer;
