import React from 'react';
import {Skeleton} from '@material-ui/lab';
import GallerySlider from '../Slider/GallerySlider/GallerySlider';
import ModalGalleryViewer from '../Slider/ModalGalleryViewer';
import {useRestaurantDetails} from '../hooks/useRestaurantDetails';

function RestaurantGallery() {
  const [isModalGalleryViewerOpen, setModalGalleryViewerOpen] = React.useState(false);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const {skeleton, data} = useRestaurantDetails();

  const slides = data?.images?.slice(0, 8);
  const modalSlides = data?.images;

  const onSlideClick = React.useCallback(slide => {
    setCurrentSlide(slide);
    setModalGalleryViewerOpen(true);
  }, []);

  const onModalGalleryClose = React.useCallback(() => {
    setModalGalleryViewerOpen(false);
  }, []);

  return (
    <section>
      {!skeleton && (
        <>
          <ModalGalleryViewer
            slides={modalSlides}
            open={isModalGalleryViewerOpen}
            onClose={onModalGalleryClose}
            currentSlide={currentSlide}
          />
          <GallerySlider slides={slides} onSlideClick={onSlideClick} />
        </>
      )}
      {skeleton && <Skeleton variant="rect" width="100%" height={400} />}
    </section>
  );
}

export default RestaurantGallery;
