import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  makeStyles,
  Typography,
  Link as MuiLink,
} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  card: {
    minWidth: 200,
  },
  media: {
    height: 0,
    paddingTop: '75%;', // 4:3 aspect ratio
  },
  capitalizedText: {
    textTransform: 'capitalize',
  },
  disabledLink: {
    outline: 'none',
    textDecoration: 'none',
    pointerEvents: 'none',
    '&:hover': {
      outline: 'none',
      textDecoration: 'none',
    },
  },
}));

function RestaurantCard({id, name, images, cuisineCategory, neighborhood, skeleton}) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <MuiLink
        tabIndex="-1"
        aria-label={name}
        aria-hidden="true"
        to={skeleton ? `#!` : `/restaurant/${id}`}
        component={skeleton ? 'span' : RouterLink}
        className={clsx({
          [classes.disabledLink]: skeleton,
        })}
      >
        <CardMedia
          component={skeleton ? Skeleton : undefined}
          className={clsx(classes.media, {})}
          image={images?.[0]?.thumbnailUrl}
          title={name}
        />
      </MuiLink>
      <CardContent>
        <MuiLink
          color="textPrimary"
          aria-label={name}
          to={skeleton ? `#!` : `/restaurant/${id}`}
          component={skeleton ? 'span' : RouterLink}
          className={clsx({
            [classes.disabledLink]: skeleton,
          })}
        >
          <Typography noWrap variant="h6" component="h2">
            {skeleton ? <Skeleton /> : name}
          </Typography>
        </MuiLink>
        <Box mt={1}>
          <Typography noWrap variant="body2" className={classes.capitalizedText}>
            {skeleton ? <Skeleton width={60} /> : cuisineCategory}
          </Typography>
          <Typography noWrap variant="body2" className={classes.capitalizedText}>
            {skeleton ? <Skeleton width={50} /> : neighborhood}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

RestaurantCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      thumbnailUrl: PropTypes.string.isRequired,
    })
  ),
  cuisineCategory: PropTypes.string,
  neighborhood: PropTypes.string,
  skeleton: PropTypes.bool,
};

RestaurantCard.defaultProps = {
  id: null,
  name: null,
  images: null,
  cuisineCategory: null,
  neighborhood: null,
  skeleton: false,
};

export default RestaurantCard;
