import React from 'react';
import moment from 'moment';
import {Box, Divider, Grid, Typography} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {Skeleton} from '@material-ui/lab';
import YearMonthFilter from './YearMonthFilter';
import BlogArchiveList from './BlogArchiveList';

/**
 * This component will get the selected year & month from state and pass it to child components for fetching the blogs
 */
function BlogArchivesContainer() {
  const month = useSelector(state => state.blogArchive.curFilter.month);
  const year = useSelector(state => state.blogArchive.curFilter.year);
  return (
    <div>
      <Box my={5}>
        <Typography align="center" variant="h4">
          {month && year && (
            <>
              Archives for {moment.utc(month, 'M', true).format('MMMM')} {year}
            </>
          )}
          {!(month && year) && <Skeleton />}
        </Typography>
      </Box>
      <Box my={5}>
        <Divider />
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <YearMonthFilter year={year} month={month} />
        </Grid>
        <Grid item xs={12} md={8}>
          <BlogArchiveList />
        </Grid>
      </Grid>
    </div>
  );
}

export default BlogArchivesContainer;
