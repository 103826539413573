import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, Typography, Link as MuiLink} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import Moment from 'react-moment';
import {Skeleton} from '@material-ui/lab';
import {useWidth} from '../../hooks/useWidth';

const useStyles = makeStyles(theme => ({
  heroImage: {
    display: 'block',
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: 0,
    paddingTop: '56.25%',
    background: '#fafafa',
    '& > *': {
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      '-webkit-backface-visibility': 'hidden',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      position: 'relative',
      left: 'calc(-1 * (100vw - 100%) / 2)',
    },
  },
  content: {
    position: 'relative',
    minWidth: 270,
    maxWidth: theme.breakpoints.values.md,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    transform: 'translateY(-50%)',
    backgroundColor: '#FFFFFF',
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      transform: 'translateY(0)',
      width: '100vw',
      position: 'relative',
      left: 'calc(-1 * (100vw - 100%) / 2)',
    },
  },
  title: {
    ...theme.typography.h3,
    fontWeight: 'bold',
    lineHeight: 1.1,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      ...theme.typography.h4,
      fontWeight: 'bold',
      lineHeight: 1.1,
    },
  },
  fontBold: {
    fontWeight: 'bold',
  },
  marginRightAllChild: {
    display: 'flex',
    alignItems: 'baseline',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  disabledLink: {
    outline: 'none',
    textDecoration: 'none',
    pointerEvents: 'none',
    '&:hover': {
      outline: 'none',
      textDecoration: 'none',
    },
  },
}));

/**
 * A component that'll show only the heading section of the blog post (i.e. title, image, category, author, date etc)
 */
function BlogHeadingSection({
  title,
  featureImage,
  category,
  author,
  city,
  tags,
  publishedAt,
  skeleton,
}) {
  const classes = useStyles();
  const width = useWidth();
  const contentContainerRef = React.useRef();

  /**
   * This hook will set the margin-bottom of the title section to 1rem for mobile screens
   * and minus (-) half of its height for other breakpoints.
   */
  React.useEffect(() => {
    if (width === 'xs') {
      contentContainerRef.current.style.marginBottom = '1rem';
    } else {
      contentContainerRef.current.style.marginBottom = `-${
        contentContainerRef.current.clientHeight / 2
      }px`;
    }
  }, [contentContainerRef, width]);

  return (
    <section>
      <Helmet>
        <title>{title ?? 'Blog'} - Khaaba</title>
      </Helmet>
      <div className={classes.heroImage}>
        {!skeleton && <img src={featureImage?.url} alt="" />}
        {skeleton && <Skeleton variant="rect" />}
      </div>
      <div ref={contentContainerRef} className={classes.content}>
        <Typography className={classes.marginRightAllChild} component="div" gutterBottom>
          <Typography className={classes.fontBold} component="span" variant="button">
            {skeleton ? (
              <Skeleton width={40} />
            ) : (
              <MuiLink component={RouterLink} to={`/blog/category/${category.slug.current}`}>
                {category.title}
              </MuiLink>
            )}
          </Typography>
          <Typography className={classes.fontBold} component="span" variant="button">
            {skeleton ? (
              <Skeleton width={40} />
            ) : (
              <MuiLink component={RouterLink} to={`/blog/city/${city.name}`}>
                {city.name}
              </MuiLink>
            )}
          </Typography>
        </Typography>
        {!!tags?.length && (
          <Typography className={classes.marginRightAllChild} component="div" gutterBottom>
            {tags.map(tag => (
              <Typography
                key={tag.slug.current}
                className={classes.fontBold}
                component="span"
                variant="subtitle2"
              >
                {skeleton ? (
                  <Skeleton width={40} />
                ) : (
                  <MuiLink component={RouterLink} to={`/blog/tag/${tag.slug.current}`}>
                    {tag.title}
                  </MuiLink>
                )}
              </Typography>
            ))}
          </Typography>
        )}
        <Typography className={classes.title} component="h1">
          {skeleton ? (
            <>
              <Skeleton width="90%" />
              <Skeleton width="60%" />
            </>
          ) : (
            title
          )}
        </Typography>
        <Typography
          color="textSecondary"
          className={classes.marginRightAllChild}
          component="div"
          variant="button"
        >
          {skeleton ? (
            <Skeleton width={40} />
          ) : (
            <Typography>
              By{' '}
              <MuiLink component={RouterLink} to={`/blog/author/${author.slug.current}`}>
                {`${author.name}`}
              </MuiLink>
            </Typography>
          )}
          <Typography>
            {skeleton ? (
              <Skeleton width={60} />
            ) : (
              <Moment date={publishedAt} format="MMMM D, YYYY" interval={0} withTitle />
            )}
          </Typography>
        </Typography>
      </div>
    </section>
  );
}

BlogHeadingSection.propTypes = {
  /**
   * title of the blog post
   */
  title: PropTypes.string,
  /**
   * image URL of the blog post
   */
  featureImage: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),

  /**
   * blog category i.e. Guides, the hit list, new on resy
   */
  category: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.shape({
      current: PropTypes.string.isRequired,
    }),
  }),
  author: PropTypes.shape({
    name: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
    slug: PropTypes.shape({
      current: PropTypes.string.isRequired,
    }).isRequired,
  }),
  /**
   * A city assocciated with the blog
   */
  city: PropTypes.shape({
    name: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
    slug: PropTypes.shape({current: PropTypes.string.isRequired}).isRequired,
  }),
  /**
   * tags for the blog
   */
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.shape({
        current: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
  /**
   * published date of the blogpost
   */
  publishedAt: PropTypes.string,
  /**
   * <BlogHeadingSection skeleton={true} /> will only show the loading skeleton
   */
  skeleton: PropTypes.bool,
};

BlogHeadingSection.defaultProps = {
  title: null,
  featureImage: null,
  category: null,
  author: null,
  city: null,
  tags: null,
  publishedAt: null,
  skeleton: false,
};

export default BlogHeadingSection;
