import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, Typography, Link as MuiLink} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';
import clsx from 'clsx';
import {Link as RouterLink} from 'react-router-dom';
import {useBlogParallax} from './useBlogParallax';

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      position: 'relative',
      left: 'calc(-1 * (100vw - 100%) / 2)',
    },
  },
  featuredImage: {
    height: '450px',
    position: 'relative',
    display: 'block',
    backgroundImage: ({imageUrl, skeleton}) => (!skeleton ? `url(${imageUrl})` : undefined),
    backgroundColor: theme.palette.grey[100],
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('xs')]: {
      height: '300px',
    },
  },
  content: {
    position: 'relative',
    minWidth: 270,
    maxWidth: theme.breakpoints.values.sm,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    transform: 'translateY(-50%)',
    backgroundColor: '#FFFFFF',
    padding: theme.spacing(2),
    transitionProperty: 'transform',
    transitionDuration: theme.transitions.duration.complex,
    transitionTimingFunction: theme.transitions.easing.easeOut,
    [theme.breakpoints.down('xs')]: {
      transform: 'translateY(0) !important',
    },
  },
  boldFont: {
    fontWeight: 'bold',
  },
  disabledLink: {
    outline: 'none',
    textDecoration: 'none',
    pointerEvents: 'none',
    '&:hover': {
      outline: 'none',
      textDecoration: 'none',
    },
  },
}));

/**
 * A featured blog card for the homepage with parallax effect
 */
function FeaturedBlogCard({id, title, featureImage, category, skeleton}) {
  const classes = useStyles({
    imageUrl: featureImage?.url,
    skeleton,
  });

  const {getContainerProps} = useBlogParallax();

  const headingContainerProps = getContainerProps();

  return (
    <div className={classes.container}>
      <MuiLink
        tabIndex="-1"
        aria-label={title}
        aria-hidden="true"
        to={skeleton ? `#!` : `/blog/${id}`}
        component={skeleton ? 'span' : RouterLink}
        className={clsx(classes.featuredImage, {
          [classes.disabledLink]: skeleton,
        })}
      >
        {skeleton && <Skeleton variant="rect" width="100%" height="100%" />}
      </MuiLink>
      <div ref={headingContainerProps.ref} className={classes.content}>
        <MuiLink
          tabIndex={skeleton ? '-1' : undefined}
          className={clsx({
            [classes.disabledLink]: skeleton,
          })}
          component={skeleton ? 'span' : RouterLink}
          to={skeleton ? `#!` : `/blog/category/${category.slug.current}`}
        >
          <Typography className={classes.boldFont} component="span" variant="subtitle1">
            {skeleton ? <Skeleton width={40} /> : category.title}
          </Typography>
        </MuiLink>
        <MuiLink
          color="textPrimary"
          tabIndex={skeleton ? '-1' : undefined}
          className={clsx({
            [classes.disabledLink]: skeleton,
          })}
          component={skeleton ? 'span' : RouterLink}
          to={skeleton ? `#!` : `/blog/${id}`}
        >
          <Typography className={classes.boldFont} component="h2" variant="h5">
            {skeleton ? (
              <>
                <Skeleton width="90%" />
                <Skeleton width="60%" />
              </>
            ) : (
              title
            )}
          </Typography>
        </MuiLink>
      </div>
    </div>
  );
}

FeaturedBlogCard.propTypes = {
  /**
   * id is required to create the URL for the blog post
   */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * the title of the blog post
   */
  title: PropTypes.string,
  /**
   * a featured image for the blog post
   */
  featureImage: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  /**
   * blog category i.e. Guides, the hit list, new on resy
   */
  category: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.shape({current: PropTypes.string.isRequired}).isRequired,
  }),
  /**
   * set it to true if you want to show the loading skeleton (if true, then no other prop is required)
   */
  skeleton: PropTypes.bool,
};

FeaturedBlogCard.defaultProps = {
  id: null,
  title: null,
  featureImage: null,
  category: null,
  skeleton: false,
};

export default FeaturedBlogCard;
