import {Box} from '@material-ui/core';
import RestaurantContentSection from 'components/RestaurantProfile/Section/RestaurantContentSection';
import React from 'react';
import Layout from '../components/Layout';
import RestaurantGallery from '../components/RestaurantProfile/Section/RestaurantGallery';

function RestaurantProfile() {
  return (
    <Layout>
      <RestaurantGallery />
      <Box pt={5}>
        <RestaurantContentSection />
      </Box>
    </Layout>
  );
}

export default RestaurantProfile;
