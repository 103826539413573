import React from 'react';
import PropTypes from 'prop-types';
import {Box, Button, makeStyles, Typography} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: theme.palette.action.active,
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  error: {
    marginBottom: theme.spacing(2),
  },
  button: {
    color: theme.palette.error.main,
  },
}));

/**
 * This component will show the error overlay with the retry button
 * around the child component.
 */
function OverlayError({children, error, message, onRetry}) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {error && (
        <div role="alert" className={classes.overlay}>
          <Typography className={classes.error} variant="subtitle2" color="error">
            {message}
          </Typography>
          <Box color="error.main">
            <Button size="small" variant="outlined" color="inherit" onClick={onRetry}>
              Try Again
            </Button>
          </Box>
        </div>
      )}
      {children}
    </div>
  );
}

OverlayError.propTypes = {
  children: PropTypes.node.isRequired,
  /**
   * set it to true if you want to show overlay
   */
  error: PropTypes.bool,
  message: PropTypes.string,
  /**
   * will be passed as onClick handler
   */
  onRetry: PropTypes.func.isRequired,
};

OverlayError.defaultProps = {
  error: false,
  message: 'Error',
};

export default OverlayError;
