import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  List,
  ListItem,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';

function FAQsModal({restaurantName, faqs, open, onClose}) {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const haveQuestions = Boolean(faqs?.length);
  return (
    <Dialog
      fullScreen={isSmallScreen}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      scroll="body"
    >
      <AppBar position="static" variant="outlined" color="default">
        <Toolbar>
          <Typography variant="h6" style={{flexGrow: 1}}>
            FAQs
          </Typography>
          <IconButton onClick={onClose} edge="start" color="inherit" aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box py={3} px={2}>
        <List>
          {!haveQuestions && (
            <ListItem>
              <div>
                <Typography style={{marginBottom: '1rem'}} variant="h6">
                  No current FAQs
                </Typography>
                <Typography>
                  {restaurantName} Restaurant has not added any frequently asked questions.
                </Typography>
              </div>
            </ListItem>
          )}
          {haveQuestions &&
            faqs.map(({question, answer}, i) => (
              <ListItem divider={i + 1 !== faqs.length}>
                <div>
                  <Typography style={{marginBottom: '1rem'}} variant="h6">
                    {question}
                  </Typography>
                  <Typography>{answer}</Typography>{' '}
                </div>
              </ListItem>
            ))}
        </List>
      </Box>
    </Dialog>
  );
}

FAQsModal.propTypes = {
  restaurantName: PropTypes.string,
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
    })
  ),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

FAQsModal.defaultProps = {
  restaurantName: null,
  faqs: null,
  open: false,
  onClose: null,
};

export default FAQsModal;
